<template>
    <section>
        <Table 
            :dataTable="table.dataTable"
            :title="table.title">
            <template v-slot:header>
                <div class="row">
                    <div class="col">
                        <div class="input-group form-search-rounded mb-1">
                            <span class="input-group-prepend">
                                <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span> 
                            <input type="text" class="form-control bg-white border-left-0 border-right-0 border-top-0 no-shadow" 
                                placeholder="Search" v-model="search">
                        </div>
                    </div>
                    <div class="col-lg-8 col-xs-12 text-right">
                        <div class="btn-responsive">
                            <button type="button" class="btn btn-primary" 
                                data-toggle="modal" data-target="#modalForm" @click="reset_model()">
                                <i class="fa fa-plus"></i> Tambah FAQ
                            </button>
                        </div>
                    </div>
                </div>                
            </template>

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Pertanyaan</th>
                        <th>Jawaban</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit"
                        data-toggle="modal" data-target="#modalForm" @click="model = data">
                        <i class="fa fa-edit"></i>
                    </button>
                    <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template> 
        </Table>

        <div class="modal fade text-left show" id="modalForm" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver class="card-content" ref="observer">
                        <form @submit.prevent="store()">
                            <div class="modal-header modal-primary white p-2">
                                <h5 class="modal-title">{{ model.id ? 'Edit' : 'Tambah' }} FAQ</h5>
                            </div>
                            <div class="modal-body pt-0 pb-0">
                                <ValidationProvider name="Pertanyaan" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Pertanyaan</label>
                                        <textarea v-model="model.question" class="form-control" placeholder="Masukkan Pertanyaan"></textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Jawaban" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Jawaban</label>
                                        <textarea v-model="model.answer" class="form-control" placeholder="Masukkan Jawaban"></textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="modal-footer p-2">
                                <button type="button" class="btn text-primary waves-effect waves-light" data-dismiss="modal">Batal</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light" :disabled="isLoading">
                                    <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Simpan</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Table from '@/components/Table.vue'
import $ from 'jquery'

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                dataTable: {
                    columns: ['counter', 'question', 'answer'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false,
            model: {
                question: null,
                answer: null
            }
		}
	},
    mounted() {
        let _ = this
        _.index()
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        index(page) {
            let _ = this
            _.axios.get('/setting/faq_customer?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus FAQ?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/setting/faq_customer/' + x.id)
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.model.id) {
                    _.update()
                    return
                }

                _.axios.post('/setting/faq_customer', _.model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.reset_model()
                        _.hide_modal()
                        _.index()
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this
            _.axios.put('/setting/faq_customer/' + _.model.id, _.model)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.reset_model()
                    _.hide_modal()
                    _.index()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        },
        hide_modal() {
            $('#modalForm').toggle()
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        },
        reset_model() {
            this.model = {
                question: null,
                answer: null
            }
            this.$refs.observer.reset()
        }
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>