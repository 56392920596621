<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <p class="font-600 mb-2 f-18">Detail Transaksi</p>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">No Order</label>
                            <p class="f-16 font-500 mb-0">{{model.order_id}}</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">Nama Pembeli</label>
                            <p class="f-16 font-500 mb-0">{{model.customer.name}}</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">Pilihan Pembayaran</label>
                            <p class="f-16 font-500 mb-0" v-html="payment_method(model.payment_method)"></p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">Tanggal Transaksi</label>
                            <p class="f-16 font-500 mb-0">{{model.created_at | moment('MM/DD/YYYY')}}</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">Email Pembeli</label>
                            <p class="f-16 font-500 mb-0">{{model.customer.email}}</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">Pilihan Pengiriman</label>
                            <p class="f-16 font-500 mb-0">{{model.shipper.name}}</p>
                            <p class="f-16 mb-0">{{shipping_pickup_time(model.shipping_pickup_time)}}</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">Jam Transaksi</label>
                            <p class="f-16 font-500 mb-0">{{model.created_at | moment('HH.mm')}}</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="text-secondary">Nomor Telepon Pembeli</label>
                            <p class="f-16 font-500 mb-0">{{model.customer.phone_number}}</p>
                        </div>
                    </div>
                </div>
                <section name="rating" v-if="total_rating">
                    <hr>
                    <p class="font-600 mb-2 f-18">Rating <star-rating v-model="total_rating" :increment="0.01" :read-only="true"></star-rating></p>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="" class="text-secondary">Kualitas</label>
                                <star-rating v-model="model.rating_quality" :star-size="30" :read-only="true"></star-rating>
                            </div>
                            <div class="form-group">
                                <label for="" class="text-secondary">Layanan</label>
                                <star-rating v-model="model.rating_service" :star-size="30" :read-only="true"></star-rating>
                            </div>
                            <div class="form-group">
                                <label for="" class="text-secondary">Harga</label>
                                <star-rating v-model="model.rating_price" :star-size="30" :read-only="true"></star-rating>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="" class="text-secondary">Saran</label>
                                <p class="f-16 font-500 mb-0">{{model.feedback}}</p>
                            </div>
                            <div class="form-group">
                                <label for="" class="text-secondary">Foto</label>
                                <div class="" v-if="model.photos">
                                    <div v-for="(photo, index) in model.photos" :key="index" 
                                        class="text-center profile-avatar">
                                        <img :src="photo" class="img-fluid rounded mt-1 mr-1 pointer" 
                                            alt="" style="width: 107px; height: 108px;" @click="lihat(photo)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr>
                <p class="font-600 mb-2 f-18">Detail Pesanan</p>
                <div class="card col-8" style="border:0.7px solid #C4C4C4;border-radius:8px;">
                    <div class="card-body mb-0 pb-0">
                        <div class="text-right">
                            <div class="btn-group">
                                <button type="button" class="btn btn-sm dropdown-toggle" :class="shipping_status_color(model.shipping_status)" 
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Ubah Status Pengiriman">
                                    {{ shipping_status(model.shipping_status) }}
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" @click="change_shipping_status('waiting')">Belum Bayar</a>
                                    <a class="dropdown-item" @click="change_shipping_status('packing')">Dikemas</a>
                                    <a class="dropdown-item" @click="change_shipping_status('on_process')">Dikirim</a>
                                    <a class="dropdown-item" @click="change_shipping_status('finish')">Selesai</a>
                                </div>
                            </div>
                            <p class="f-12 mb-0">{{model.shipping_histories[model.shipping_histories.length-1].created_at | moment('MM/DD/YYYY')}}</p>
                        </div>
                    </div>
                    <hr>
                    <div class="card-body p-0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nama Produk</th>
                                    <th class="text-center">Jumlah</th>
                                    <th class="text-center">Harga Satuan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cart in model.carts" :key="cart.id">
                                    <td>{{ cart.id_product }}</td>
                                    <td><img :src="cart.product_thumbnail" alt="" style="width:48px;height:48px;" class="rounded mr-2">{{ cart.product_name }}</td>
                                    <td class="text-center">{{ cart.quantity | numFormat }}</td>
                                    <td class="text-center">{{ cart.selling_price_per_kg | currency }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-body mb-0 pb-0 pt-0">
                        <p class="f-16 mb-0" style="color:#8E8E8E">Subtotal untuk produk <span class="pull-right">{{ model.price_subtotal | currency }}</span></p>
                        <p class="f-16 mb-0" style="color:#8E8E8E">Promo 
                            <span class="text-warning f-14 font-600" v-if="model.promo_name != ''">{{model.promo_name}}</span> 
                            <span class="pull-right text-danger" v-if="model.promo_discount_amount > 0">{{ -model.promo_discount_amount | currency }}</span>
                            <span class="pull-right text-danger" v-else>{{ 0 | currency }}</span>
                        </p>
                        <p class="f-16 mb-0" style="color:#8E8E8E">Pengiriman <span class="pull-right">{{ model.shipping_total_price | currency }}</span></p>
                    </div>
                    <hr>
                    <div class="card-body mb-0 pt-0">
                        <p class="f-16 mb-0">Total Pembayaran <span class="pull-right f-18 font-600 text-success">{{ model.price_final | currency }}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StarRating from 'vue-star-rating'
export default {
    components: {
        StarRating
    },
    computed: {
        total_rating: function()
        {
            return (this.model.rating_quality + this.model.rating_service + this.model.rating_price) / 3
        }
    },
    data() {
        return {
            model: {
                customer: {},
                shipper: {},
                shipping_histories: [{
                    created_at: ''
                }],
                carts: []
            },
            isLoading: false
        }
    },
    methods: {
        show() {
            let _ = this
            _.axios.get('/transaction/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        shipping_pickup_time(time) {
            let time_for_human = ''
            switch (time) {
                case 'morning':
                    time_for_human = 'Pagi (07.00-10.00)'
                    break;
                case 'afternoon':
                    time_for_human = 'Siang (11.00-15.00)'
                    break;
                case 'evening':
                    time_for_human = 'Sore (15.00-18.00)'
                    break;
                case 'night':
                    time_for_human = 'Malam (18.00-21.00)'
                    break;
            
                default:
                    break;
            }
            return time_for_human
        },
        payment_method(method) {
            let badge = ''
            switch (method) {
                case 'cod':
                    badge = "<div class='badge badge-md badge-success'>Cash</div>"
                    break;
                case 'payment_gateaway':
                    badge = "<div class='badge badge-md badge-primary'>Midtrans</div>"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        shipping_status(status) {
            let badge = ''
            switch (status) {
                case 'waiting':
                    badge = "Belum Bayar"
                    break;
                case 'packing':
                    badge = "Dikemas"
                    break;
                case 'on_process':
                    badge = "Dikirim"
                    break;
                case 'finish':
                    badge = "Selesai"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        shipping_status_color(status) {
            let badge = ''
            switch (status) {
                case 'waiting':
                    badge = "btn-warning"
                    break;
                case 'finish':
                    badge = "btn-success"
                    break;
                case 'packing':
                    badge = "btn-outline-info"
                    break;
                case 'on_process':
                    badge = "btn-info"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        change_shipping_status(status) {
            let _ = this
            _.axios.put('/transaction/' + _.$route.params.id, { shipping_status: status })
                .then(() => {
                    _.show()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded p-1'
                }
            })
        },
    },
    mounted() {
        let _ = this
        _.show()
    }
}
</script>