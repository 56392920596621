<template>
    <div class="card">
        <div class="card-body">
            <h3 class="font-600 mb-1">Manajemen Sponsor</h3>
            <ul class="nav nav-tabs mt-1" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="pembeli-tab" data-toggle="tab" href="#pembeli" role="tab" aria-controls="pembeli" aria-selected="true">
                        Banner At Home
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" id="admin-tab" data-toggle="tab" href="#admin" role="tab" aria-controls="admin" aria-selected="false">
                        Promo Pilihan
                    </a>
                </li> -->
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="pembeli" role="tabpanel" aria-labelledby="pembeli-tab">
                    <Banner/>
                </div>
                <!-- <div class="tab-pane fade show" id="admin" role="tabpanel" aria-labelledby="admin-tab">
                    <Produk/>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Banner from '@/views/sponsor/banner/Index.vue'
// import Produk from '@/views/promo/produk/Index.vue'

export default {
    components: {
        Banner,
        // Produk
    }
}
</script>