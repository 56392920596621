<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row" v-can="'penjual'">
                        <div class="col">
                            <!-- <div class="input-group form-search-rounded mb-1">
                                <span class="input-group-prepend">
                                    <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span> 
                                <input type="text" class="form-control bg-white border-left-0 border-right-0 border-top-0 no-shadow" 
                                    placeholder="Search" v-model="search">
                            </div> -->
                        </div>
                        <div class="col-lg-8 col-xs-12 text-right">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary mb-1" data-target="#addForm" data-toggle="modal" @click="reset_model_add()">
                                    <i class="fa fa-plus"></i> Tambah Permintaan
                                </button>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">
                                {{ data }}
                            </th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block" v-if="data.status == 'waiting'" v-can="'admin'">
                        <button type="button" class="btn btn-primary btn-sm waves-effect waves-light" title="Approve" data-toggle="modal" data-target="#approveForm"
                            @click="detailRequest = data">
                            <i class="fa fa-check"></i> Setuju
                        </button>&nbsp;
                        <button type="button" class="btn btn-danger btn-sm waves-effect waves-light" title="Tolak" data-toggle="modal" data-target="#rejectForm"
                            @click="detailRequest = data">
                            <i class="fa fa-times"></i> Tolak
                        </button>
                    </div>
                </template> 
            </Table>
        </div>

        <div class="modal fade text-left show" id="addForm" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver class="card-content" ref="observerAdd">
                        <form @submit.prevent="store()">
                            <div class="modal-header modal-primary white p-2">
                                <h5 class="modal-title">Tambah Permintaan</h5>
                            </div>
                            <div class="modal-body pt-0 pb-0">
                                <ValidationProvider name="Produk" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Pilih Produk</label>
                                        <select name="" id="" v-model="model.id_product" class="form-control">
                                            <option :value="null">Pilih Produk</option>
                                            <option v-for="product in products" :key="product.id" :value="product.id">
                                                {{ product.name }}
                                            </option>
                                        </select>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Permintaan Restock" v-slot="{ errors }" :rules="{ required: true }">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Permintaan Restock</label>
                                        <input type="number" class="form-control" placeholder="Masukkan stok" v-model="model.requested_stock">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="modal-footer p-2">
                                <button type="button" class="btn text-primary waves-effect waves-light closeModal" data-dismiss="modal">Batal</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light" :disabled="isLoading">
                                    <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Simpan</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="approveForm" tabindex="-1" role="dialog" v-if="detailRequest">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver class="card-content" ref="observerApprove">
                        <form @submit.prevent="approve()">
                            <div class="modal-header modal-primary white p-2">
                                <h5 class="modal-title">Setujui Permintaan Restock</h5>
                            </div>
                            <div class="modal-body pt-0 pb-0">
                                <p>Stok Saat Ini: <b>{{ detailRequest.product.current_stock | numFormat }}</b></p>
                                <p>Permintaan Stok: <b>{{ detailRequest.requested_stock | numFormat }}</b></p>
                                <ValidationProvider name="Jumlah stok disetujui" v-slot="{ errors }" :rules="{ required: true, max_value: detailRequest.requested_stock }">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Jumlah Stok Disetujui</label>
                                        <input type="number" class="form-control" placeholder="Masukkan jumlah stok" v-model="detailRequest.approved_stock">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="modal-footer p-2">
                                <button type="button" class="btn text-primary waves-effect waves-light closeModal" data-dismiss="modal">Batal</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light" :disabled="isLoading">
                                    <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Simpan</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="rejectForm" tabindex="-1" role="dialog" v-if="detailRequest">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver class="card-content" ref="observerReject">
                        <form @submit.prevent="reject()">
                            <div class="modal-header modal-primary white p-2">
                                <h5 class="modal-title">Tolak Permintaan Restock</h5>
                            </div>
                            <div class="modal-body pt-0 pb-0">
                                <ValidationProvider name="Alasan Ditolak" v-slot="{ errors }" :rules="{ required: true }">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alasan Ditolak</label>
                                        <textarea cols="30" rows="5" class="form-control" v-model="detailRequest.reject_reason"></textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="modal-footer p-2">
                                <button type="button" class="btn text-primary waves-effect waves-light closeModal" data-dismiss="modal">Batal</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light" :disabled="isLoading">
                                    <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Simpan</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Permintaan Restock',
                dataTable: {
                    header: ['No', 'ID', 'Nama Penjual', 'Nama Produk', 'Permintaan Stok', 'Permintaan Stok Disetujui', 'Alasan Ditolak', 'Status', 'Action'],
                    columns: ['counter', 'id', 'user_name', 'product_name', 'requested_stock', 'approved_stock', 'reject_reason', 'status'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false,
            user: JSON.parse(localStorage.getItem('user')),
            detailRequest: null,
            model: {
                id_user: null,
                id_product: null,
                id_user_product: null,
                requested_stock: null,
                status: 'waiting'
            },
            products: []
		}
	},
    mounted() {
        let _ = this
        _.index()
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
        _.getProducts()
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        index(page) {
            let _ = this
            let idUser = _.user.role == 'penjual' ? _.user.id : ''

            _.axios.get('/request_restock?page=' + page + '&limit=' + _.limit + '&id_user=' + idUser)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getProducts() {
            let _ = this
            _.axios.get('/product?id_user=' + _.user.id)
                .then(resp=>{
                    _.products = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observerAdd.validate()
            if (isValid){
                _.isLoading = true

                let searchProduct = _.products.map( x => x.id ).indexOf( _.model.id_product )
                if ( searchProduct == -1 ) {
                    _.isLoading = false
                    return;
                }

                let data = {
                    id_user: _.user.id,
                    id_product: _.model.id_product,
                    id_user_product: _.products[searchProduct].user_products_id,
                    requested_stock: _.model.requested_stock,
                    status: 'waiting'
                }

                _.axios.post('/request_restock', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.reset_model_add()
                        _.hide_modal()
                        _.index()
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        async approve() {
            let _ = this
            const isValid = await _.$refs.observerApprove.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    ..._.detailRequest,
                    status: 'approved'
                }

                _.axios.put('/request_restock/' + _.detailRequest.id, data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.reset_model_approve()
                        _.hide_modal()
                        _.index()
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        async reject() {
            let _ = this
            const isValid = await _.$refs.observerReject.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    ..._.detailRequest,
                    status: 'rejected'
                }

                _.axios.put('/request_restock/' + _.detailRequest.id, data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.reset_model_reject()
                        _.hide_modal()
                        _.index()
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        hide_modal() {
            window.$('.closeModal').click()
        },
        reset_model_approve() {
            this.detailRequest = null
            this.$refs.observerApprove.reset()
        },
        reset_model_reject() {
            this.detailRequest = null
            this.$refs.observerReject.reset()
        },
        reset_model_add() {
            this.model = {
                id_user: null,
                id_product: null,
                id_user_product: null,
                requested_stock: null,
                status: 'waiting'
            }
            this.$refs.observerAdd.reset()
        }
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>