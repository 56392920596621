<template>
    <section>
        <div class="card">
            <div class="card-body">
                <h3 class="font-600 mb-1">Manajemen Tiket</h3>
                <div class="row overflow-hidden">
                    <div class="col-3 pr-0">
                        <!-- <h4 class="font-600">All Chats</h4> -->
                        <div class="input-group mb-1">
                            <input type="text" class="form-control border-0" 
                                placeholder="Search" v-model="search">
                            <span class="input-group-append">
                                <div class="input-group-text border-0">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span> 
                        </div>
                        <hr class="mb-0">
                        <div class="messages-box" v-if="chats.length == 0">
                            <p class="text-center" style="margin-top:200px;">Tidak ada data</p>
                        </div>
                        <div class="messages-box" v-else id="chat-list">
                            <div v-for="chat in chats" :key="chat.id">
                                <div class="media pt-1 pb-1 pr-1 pl-1 pointer" 
                                    :class="active_chat && active_chat.id == chat.id ? 'active' : ''"
                                    @click="
                                        active_chat = chat, 
                                        chat.unread_message = 0,
                                        chat.is_new = false
                                    ">
                                    <img :src="chat.user && chat.user.photo_profile ? chat.user.photo_profile : '/assets/img/icon/profile.png'" alt="user" width="50" class="rounded-circle" style="width:50px;height:50px;object-fit:cover">
                                    <div class="media-body ml-1 align-self-center">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <p class="mb-0 f-14 font-600">{{chat.order_id}}</p>
                                            <small class="f-10 font-weight-bold" v-if="$moment(chat.updated_at).format('YYYY-MM-DD') == today">
                                                {{chat.updated_at | moment('HH:mm')}}
                                            </small>
                                            <small class="f-10 font-weight-bold" v-else>
                                                {{chat.updated_at | moment('DD MMM YYYY')}}
                                            </small>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <p class="mb-0 f-12" v-if="chat.last_message">
                                                {{ chat.last_message.length > 20 ? chat.last_message.substring(0, 20) + '...' : chat.last_message }}
                                            </p>
                                            <small class="f-10 badge badge-sm" v-if="chat.unread_message > 0">{{ chat.unread_message | numFormat }}</small>
                                            <small class="f-10 badge badge-sm badge-danger ml-auto" v-else-if="chat.is_new">New</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 text-center" v-if="active_chat == null">
                        <img src="/assets/img/bro.png" alt="" class="img-fluid" style="margin-top: 200px;width: 235px;">
                        <p>Silahkan memulai percakapan</p>
                    </div>
                    <div class="col-9 text-center" v-else-if="isLoading">
                        <img src="/assets/img/bro.png" alt="" class="img-fluid" style="margin-top: 200px;width: 235px;">
                        <p>Harap tunggu</p>
                    </div>
                    <div class="col-9" v-else>
                        <div class="row">
                            <img :src="detail.user && detail.user.photo_profile ? detail.user.photo_profile:'/assets/img/icon/profile.png'" alt="" class="avatar-small border-0 ml-1 mr-1" style="width:50px;height:50px;object-fit:cover">
                            <div class="col align-self-center p-0">
                                <p class="f-16 font-600 mb-0">Order ID: {{detail.order_id}}</p>
                                <p class="text-secondary f-14 mb-0">{{detail.user.name}}</p>
                            </div>
                            <div class="col align-self-center text-right">
                                <p class="f-16 font-600 mb-0">Total Pesanan: {{detail.transaction.price_final | currency}}</p>
                                <div v-html="shipping_status(detail.transaction.shipping_status)"></div>
                            </div>
                        </div>
                        <hr>
                        <div id="chat-window" class="chat-box pr-1">
                            <div v-for="(date, index) in messages" :key="index">
                                <p class="text-center f-12" v-if="index == today">Hari Ini</p>
                                <p class="text-center f-12" v-else>{{index | moment('D MMMM YYYY')}}</p>
                                <div v-for="message in date" :key="message.id">
                                    <div class="media mb-1 border-0" v-if="message.sender.role == 'customer'">
                                        <div class="media-body ml-1">
                                            <div class="row">
                                                <div class="p-1" 
                                                    style="
                                                        border-radius: 0px 30px 30px 30px;
                                                        background:#F2F2F2;
                                                        max-width: 40%;
                                                    ">
                                                    <p class="f-14 mb-0" style="color:#333335;">{{message.message}}</p>
                                                </div>
                                                <p class="f-10 align-self-center mb-0 ml-1">{{message.created_at | moment('HH.mm')}}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="media mb-1 border-0" v-else>
                                        <div class="media-body ml-1 mr-1">
                                            <div class="row ">
                                                <p class="f-10 text-primary text-right align-self-center mb-0 mr-1 ml-auto">
                                                    <span class="f-12 font-500">{{message.sender.name}}<br></span>
                                                    {{message.created_at | moment('HH.mm')}}
                                                    <!-- <span class="f-12 font-500" v-if="message.read_at">
                                                        <i class="material-icons f-14" style="vertical-align:middle">done_all</i>
                                                    </span> -->
                                                </p>
                                                <div class="p-1" 
                                                    style="
                                                        border-radius: 30px 30px 0px 30px;
                                                        background:#225493;
                                                        max-width: 40%;
                                                        height: fit-content;
                                                    ">
                                                    <p class="f-14 mb-0" style="color:#fff;">{{message.message}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="input-group" v-show="detail.transaction.shipping_status != 'finish'">
                            <div class="input-group-prepend">
                                <emoji-picker @emoji="append" :search="search" class="align-self-end">
                                    <div
                                        class="emoji-invoker"
                                        slot="emoji-invoker"
                                        slot-scope="{ events: { click: clickEvent } }"
                                        @click.stop="clickEvent">
                                        <i class="fa fa-smile-o p-1 pointer f-24"></i>
                                    </div>

                                    <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                                        <div class="emoji-picker" :style="{ top: display.y*-0.37 + 'px', left: display.x*0 + 'px' }">
                                        <div>
                                            <div v-for="(emojiGroup, category) in emojis" :key="category">
                                                <h5>{{ category }}</h5>
                                                <div class="emojis">
                                                    <span
                                                        v-for="(emoji, emojiName) in emojiGroup"
                                                        :key="emojiName"
                                                        @click="insert(emoji)"
                                                        :title="emojiName">
                                                        {{ emoji }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </emoji-picker>
                            </div>
                            <input type="text" ref="message" v-model="message" placeholder="Type a message" class="form-control rounded border-0 no-shadow" :disabled="sendingMessage"
                                v-on:keyup.enter="send_message()"> 
                            <div class="input-group-append">
                                <button type="button" class="btn text-primary" @click="send_message()" :disabled="sendingMessage">
                                    <span v-if="sendingMessage"><i class="fa fa-spinner fa-spin f-24 ml-1"></i></span>
                                    <span v-else>Send</span>
                                </button>
                            </div>
                        </div>
                        <p v-show="detail.transaction.shipping_status == 'finish'" class="text-center mb-0 mt-2">Transaksi sudah selesai</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style>
    .emoji-invoker:hover {
        transform: scale(1.1);
    }
    .emoji-invoker > svg {
        fill: #b1c6d0;
    }
    .emoji-picker {
        position: absolute;
        z-index: 1;
        font-family: Montserrat;
        border: 1px solid #ccc;
        width: 17rem;
        height: 20rem;
        overflow: scroll;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        background: #fff;
        box-shadow: 1px 1px 8px #c7dbe6;
    }
    .emoji-picker__search {
        display: flex;
    }
    .emoji-picker__search > input {
        flex: 1;
        border-radius: 10rem;
        border: 1px solid #ccc;
        padding: 0.5rem 1rem;
        outline: none;
    }
    .emoji-picker h5 {
        margin-bottom: 0;
        color: #b1b1b1;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: default;
    }
    .emoji-picker .emojis {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .emoji-picker .emojis:after {
        content: "";
        flex: auto;
    }
    .emoji-picker .emojis span {
        padding: 0.2rem;
        cursor: pointer;
        border-radius: 5px;
    }
    .emoji-picker .emojis span:hover {
        background: #ececec;
        cursor: pointer;
    }
</style>
<script>
import EmojiPicker from 'vue-emoji-picker'

export default {
    components: {
        EmojiPicker,
    },
    data() {
        return {
            search: '',
            chats: [],
            detail: {
                user: {
                    name: '',
                    photo_profile: ''
                }
            },
            messages: [],
            active_chat: null,
            today: null,
            message: '',
            isLoading: false,
            sendingMessage: false
        }
    },
    methods: {
        append(emoji) {
            this.message += emoji
            this.$forceUpdate()
        },
        shipping_status(status) {
            let badge = ''
            switch (status) {
                case 'waiting':
                    badge = "<div class='badge badge-sm badge-warning'>Belum Bayar</div>"
                    break;
                case 'packing':
                    badge = "<div class='badge badge-sm badge-outline-info'>Dikemas</div>"
                    break;
                case 'on_process':
                    badge = "<div class='badge badge-sm badge-info'>Dikirim</div>"
                    break;
                case 'finish':
                    badge = "<div class='badge badge-sm badge-success'>Selesai</div>"
                    break;
                default:
                    break;
            }

            return badge
        },
        get_list()
        {
            let _ = this
            _.axios.get('/chat?key=' + _.search)
                .then( resp => {
                    _.chats = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        get_detail() {
            let _ = this
            _.axios.get('/chat/' + _.active_chat.id)
                .then( resp => {
                    _.detail = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        get_messages() {
            let _ = this
            _.isLoading = true
            _.axios.get('/chat/message?id_user_chat=' + _.active_chat.id)
                .then( resp => {
                    _.messages = resp.data.data

                    let finalObj = {}
                    _.messages.forEach((message) => {
                        const date = message.created_at.split('T')[0]
                        if (finalObj[date]) {
                            finalObj[date].push(message);
                        } else {
                            finalObj[date] = [message];
                        }
                    })

                    _.messages = finalObj

                    setTimeout(() => {
                        var elm = document.getElementById('chat-window')
                        elm.scrollTop = elm.scrollHeight
                    })
                    _.isLoading = false
                    this.$nextTick(() => this.$refs.message.focus())
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        send_message() {
            let _ = this
            if (_.message == '') {
                _.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: 'Pesan harus diisi'
                })
                return;
            }

            _.sendingMessage = true
            let data = {
                id_user_chat: _.active_chat.id,
                message: _.message
            };
            _.axios.post('/chat/message', data)
                .then(() => {
                    _.message = ''
                    _.sendingMessage = false
                    this.$nextTick(() => this.$refs.message.focus())
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                    _.sendingMessage = false
                    this.$nextTick(() => this.$refs.message.focus())
                })
        }
    },
    mounted() {
        let _ = this
        _.get_list()
        _.$moment.locale('id')
        _.today = _.$moment().format('YYYY-MM-DD')

        window.Pusher.unsubscribe('user-admin')
        window.Pusher.subscribe('user-admin')
            .bind('user-chat', function(e) {
                let id_user_chat = e.chat.id_user_chat

                if (_.active_chat != null && id_user_chat == _.active_chat.id) {
                    if (_.messages[_.today]) {
                        _.messages[_.today].push(e.chat)
                        _.$forceUpdate()
                    } else {
                        _.messages[_.today] = [e.chat]
                        _.$forceUpdate()
                    }

                    _.active_chat.last_message = e.chat.message
                    _.active_chat.updated_at = e.chat.created_at

                    setTimeout(() => {
                        var elm = document.getElementById('chat-window')
                        elm.scrollTop = elm.scrollHeight
                    })
                    setTimeout(() => {
                        var elm = document.getElementById('chat-list')
                        elm.scrollTop = -elm.scrollHeight
                    })
                } else {
                    let searchChat = _.chats.filter(x => x.id == id_user_chat)[0]
                    searchChat.last_message = e.chat.message
                    searchChat.updated_at = e.chat.created_at
                    searchChat.unread_message += 1
                    _.$forceUpdate()
                }

                _.chats.sort(function(x,y){ return x.id == id_user_chat ? -1 : y == id_user_chat ? 1 : 0; })
            });

        window.Pusher.subscribe('user-admin')
            .bind('new-transaction', function(e) {
                if (e.transaction) {
                    let id_user_chat = e.transaction.id
                    let searchChat = _.chats.filter(x => x.id == id_user_chat)
                    if ( searchChat.length == 0 ) {
                        e.transaction.is_new = true
                        _.chats.push(e.transaction)
                        _.chats.sort(function(x,y){ return x.id == id_user_chat ? -1 : y == id_user_chat ? 1 : 0; })
                        _.$forceUpdate()
                    }                    
                }
            });
    },
    watch: {
        active_chat: function()
        {
            let _ = this

            if (_.active_chat != null) {
                _.get_detail()
                _.get_messages()
                _.message = ''
            }
        },
        search: function()
        {
            let _ = this
            _.get_list()
            _.message = ''
            _.active_chat = null
        }
    }
}
</script>