<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">Detail Pembeli</h3>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <img :src="model.photo_profile ? model.photo_profile:'/assets/img/icon/profile.png'" alt="" class="avatar-small border-0 ml-1 mr-1">
                            <div class="col-lg-8 col-xs-12 align-self-center p-0">
                                <p class="f-16 font-600 mb-0">{{model.name}}</p>
                                <p class="text-secondary f-14 mb-0">Pembeli</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-sm btn-outline-primary mr-1" @click="$router.push('/users/customer/edit/'+$route.params.id)">Edit</button>
                        <div class="badge badge-md badge-primary" v-if="model.is_active == true">• Active</div>
                        <div class="badge badge-md badge-danger" v-else>• Not Active</div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label for="" class="text-secondary">Email</label>
                            <p class="f-16 font-500 mb-0">{{model.email}}</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="" class="text-secondary">Nomor Telepon</label>
                            <p class="f-16 font-500 mb-0">{{model.phone_number}}</p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="" class="text-secondary">Alamat</label>
                            <p class="f-16 font-500 mb-0">{{model.address}}</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="" class="text-secondary">KTP</label>
                            <img :src="model.photo_ktp" alt="" class="img-fluid rounded">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="" class="text-secondary">NPWP</label>
                            <img :src="model.photo_npwp" alt="" class="img-fluid rounded">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                photo_profile: null,
                name: null,
                email: null,
                phone_number: null,
                address: null,
                is_active: null
            },
            isLoading: false
        }
    },
    methods: {
        show() {
            let _ = this
            _.axios.get('/user/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        }
    },
    mounted() {
        let _ = this
        _.show()
    }
}
</script>