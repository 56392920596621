<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Akun Pembeli</h3>                        
                <ValidationObserver class="card-content" ref="observer">
                    <form @submit.prevent="store()">
                        <div class="row">
                            <div class="col-lg-6">
                                <ValidationProvider name="Foto Profil" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Foto Profil</label>
                                        <div v-if="model.photo_profile" class="text-center profile-avatar">
                                            <img :src="model.photo_profile" class="img-fluid rounded mt-1 mr-1 pointer" 
                                                alt="" style="width: 150px; height: 150px;"><br>
                                            <div class="edit-avatar">
                                                <button 
                                                    @click="model.photo_profile = null"
                                                    class="btn btn-rounded btn-danger" 
                                                    title="Delete"
                                                    type="button">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <label for="file-upload" class="pointer">
                                                <img src="/assets/img/upload_image.png" alt="" class="img-fluid">
                                            </label>
                                            <input id="file-upload" class="input-file-avatar d-none" type="file" @change="upload($event)">
                                        </div>
                                        <input type="hidden" v-model="model.photo_profile">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Nama</label>
                                        <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan Nama">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Email</label>
                                        <input type="text" v-model="model.email" class="form-control" placeholder="Masukkan Email">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Password" v-slot="{ errors }" rules="required|min:8" v-if="!$route.params.id">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Password</label>
                                        <input type="password" v-model="model.password" class="form-control" placeholder="Masukkan Password"
                                        autocorrect="off" spellcheck="false" autocomplete="off"
                                        readonly onfocus="this.removeAttribute('readonly');" >
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-lg-6">
                                <ValidationProvider name="Nomor Telepon" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Nomor Telepon</label>
                                        <input type="number" v-model="model.phone_number" class="form-control" placeholder="Masukkan Nomor Telepon">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Alamat</label>
                                        <textarea name="" id="" cols="30" rows="5" class="form-control" v-model="model.address" placeholder="Masukkan Alamat"></textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Status</label>
                                        <select name="" id="" class="form-control" v-model="model.is_active">
                                            <option :value="null">Pilih Status</option>
                                            <option :value="true">Aktif</option>
                                            <option :value="false">Tidak Aktif</option>
                                        </select>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="text-right">
                            <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                            <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                photo_profile: null,
                name: null,
                email: null,
                phone_number: null,
                address: null,
                is_active: null
            },
            isLoading: false
        }
    },
    methods: {
        show() {
            let _ = this
            _.axios.get('/user/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.$route.params.id) {
                    _.update()
                    return
                }

                let data = {
                    ..._.model,
                    role: 'customer'
                }
                _.axios.post('/user', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.$router.push('/users')
                    })
                    .catch(err => {
                        console.log(err)
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: 'Email sudah terdaftar'
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this
            _.axios.put('/user/' + _.$route.params.id, _.model)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.$router.push('/users')
                })
                .catch(err => {
                    console.log(err)
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: 'Email sudah terdaftar'
                    })
                    _.isLoading = false
                })  
        },
        upload(event){
            let _ = this
            let file = event.target.files[0]
            let formData = new FormData()
            formData.append('file', file)

            _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    _.model.photo_profile = resp.data.data.url
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
    },
    mounted() {
        let _ = this

        if (_.$route.params.id){
            _.show()
        }
    }
}
</script>