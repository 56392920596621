<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col">
                            <div class="input-group form-search-rounded mb-1">
                                <span class="input-group-prepend">
                                    <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span> 
                                <input type="text" class="form-control bg-white border-left-0 border-right-0 border-top-0 no-shadow" 
                                    placeholder="Search" v-model="search">
                            </div>
                        </div>
                        <div class="col-lg-8 col-xs-12 text-right">
                            <div class="btn-responsive">
                                    <button type="button" class="btn btn-primary" data-target="#modalForm" data-toggle="modal" @click="reset_model()">
                                        <i class="fa fa-plus"></i> Tambah Produk
                                    </button>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>ID</th>
                            <th style="width: 50px;"></th>
                            <th>Nama Produk</th>
                            <th>Nama Kategori</th>
                            <th>Deskripsi</th>
                            <th>Harga Dasar</th>
                            <th>Stok Saat Ini</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block">
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>

        <div class="modal fade text-left show" id="modalForm" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver class="card-content" ref="observer">
                        <form @submit.prevent="store()">
                            <div class="modal-header modal-primary white p-2">
                                <h5 class="modal-title">Tambah Produk</h5>
                            </div>
                            <div class="modal-body pt-0 pb-0">
                                <ValidationProvider name="Produk" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Pilih Produk</label>
                                        <select name="" id="" v-model="model.id_product" class="form-control">
                                            <option :value="null">Pilih Produk</option>
                                            <option v-for="product in products" :key="product.id" :value="product.id">
                                                {{ product.name }}
                                            </option>
                                        </select>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="modal-footer p-2">
                                <button type="button" class="btn text-primary waves-effect waves-light closeModal" data-dismiss="modal">Batal</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light" :disabled="isLoading">
                                    <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Simpan</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Manajemen Produk',
                dataTable: {
                    header: ['No', 'ID', 'Nama Produk', 'Nama Kategori', 'Deskripsi', 'Harga Dasar', 'Stok Saat Ini', 'Action'],
                    columns: ['counter', 'id', 'photos', 'name', 'category_name', 'description', 'price', 'current_stock'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false,
            user: JSON.parse(localStorage.getItem('user')),
            model: {
                id_user: null,
                id_product: null,
                current_stock: 0
            },
            products: []
		}
	},
    mounted() {
        let _ = this
        _.index()
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
        _.getProducts()
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        index(page) {
            let _ = this
            _.axios.get('/product?key=' + _.search + '&page=' + page + '&limit=' + _.limit + '&id_user=' + _.user.id)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getProducts() {
            let _ = this
            _.axios.get('/product')
                .then(resp=>{
                    _.products = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Produk?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/product/user/' + x.user_products_id)
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                _.axios.post('/product/user', _.model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.reset_model()
                        _.hide_modal()
                        _.index()
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        hide_modal() {
            window.$('#modalForm .closeModal').click()
        },
        reset_model() {
            this.model = {
                id_user: this.user.id,
                id_product: null,
                current_stock: 0
            }
            this.$refs.observer.reset()
        }
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>