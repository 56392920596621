<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Notifikasi</h3>                        
                <ValidationObserver class="card-content" ref="observer">
                    <form @submit.prevent="store()">
                        <ValidationProvider name="Judul Notifikasi" v-slot="{ errors }" rules="required">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Judul Notifikasi</label>
                                <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan Judul Notifikasi">
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Isi Notifikasi" v-slot="{ errors }" rules="required" >
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="" class="required">Isi Notifikasi</label>
                                <quill-editor v-model="model.description" :options="editorOption"></quill-editor>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Waktu Publish Notifikasi" v-slot="{ errors }" rules="required">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Waktu Publish Notifikasi</label>
                                <div class="row pl-1 mt-1 mb-1">
                                    <div class="col-3 row">
                                        <input type="radio" class="align-self-center mr-1" value="now" v-model="model.publish_type">
                                        <label for="" class="align-self-center mb-0">Sekarang</label>
                                    </div>
                                    <div class="col-3 row mr-0">
                                        <input type="radio" class="align-self-center mr-1" value="custom" v-model="model.publish_type">
                                        <label for="" class="align-self-center mb-0">Pilih Waktu</label>
                                    </div>
                                </div>

                                <div class="w-50" v-if="model.publish_type == 'custom'">
                                    <ValidationProvider name="Tanggal Publish" v-slot="{ errors }" rules="required">
                                        <div class="form-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                            <label>Tanggal & Jam Publish</label>
                                            <date-picker
                                                v-model="model.publish_datetime"
                                                format="DD MMMM YYYY, HH:mm WIB"
                                                type="datetime"
                                                :input-class="'form-control'"
                                                value-type="YYYY-MM-DD HH:mm:ss"
                                                placeholder="Pilih Tanggal & Jam Publish"
                                                style="width: 100%;"
                                                :time-picker-options="{start: '00:00', step:'00:01' , end: '23:30', format: 'HH:mm' }"
                                                :disabled-date="disabledBeforeToday"
                                                :disabled-time="disableTime">
                                            </date-picker>
                                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>

                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Penerima Notifikasi" v-slot="{ errors }" rules="required" >
                            <div class="form-group w-50" :class="{ 'validation-warning': errors[0] }">
                                <label for="" class="required">Penerima Notifikasi</label>
                                <select name="" id="" class="form-control" v-model="model.recipient_type">
                                    <option value="Semua">Semua</option>
                                    <option value="Khusus">Khusus</option>
                                </select>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Recipient" rules="required" v-if="model.recipient_type == 'Khusus'">
                            <div class="form-group w-50">
                                <label class="form-label">Kepada</label>
                                <multiselect 
                                    v-model="selectedRecipients" 
                                    id="id" 
                                    track-by="id" 
                                    placeholder="Pilih penerima" 
                                    :options="recipients" 
                                    :multiple="true" 
                                    :custom-label="customLabel"
                                    :close-on-select="false"
                                    :loading="isLoading"/>
                            </div>
                        </ValidationProvider>
                        
                        <div class="text-right">
                            <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                            <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
const toolbarOptions = [
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['clean'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['blockquote', 'code-block']
]
export default {
    data() {
        return {
            model: {
                name: null,
                description: null,
                publish_type: 'now',
                recipient_type: 'Semua',
                recipient_roles: []
            },
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    },
                },
                placeholder: "Masukkan Isi Notifikasi"
            },
            isLoading: false,
            selectedRecipients: [],
            recipients: []
        }
    },
    methods: {
        show() {
            let _ = this
            _.axios.get('/role/notification/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data

                    if (_.model.recipient_roles != null) {
                        for (let i = 0; i < _.model.recipient_roles.length; i++) {
                            let searchRecipient = _.recipients.map(x => x.id).indexOf(_.model.recipient_roles[i])
                            if ( searchRecipient != -1 ) {
                                _.selectedRecipients.push(_.recipients[searchRecipient])
                            }
                        }
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.$route.params.id) {
                    _.update()
                    return
                }

                _.model.recipient_roles = []
                _.selectedRecipients.forEach(elm => {
                    _.model.recipient_roles.push(elm.id)
                });
                
                let data = {
                    ..._.model,
                    recipient_roles: _.model.recipient_roles.length > 0 ? JSON.stringify(_.model.recipient_roles) : null
                }

                _.axios.post('/role/notification', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.$router.push('/roles/notifications')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this

            _.model.recipient_roles = []
            _.selectedRecipients.forEach(elm => {
                _.model.recipient_roles.push(elm.id)
            });
            
            let data = {
                ..._.model,
                recipient_roles: _.model.recipient_roles.length > 0 ? JSON.stringify(_.model.recipient_roles) : null
            }

            _.axios.put('/role/notification/' + _.$route.params.id, data)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message,
                    })
                    _.isLoading = false
                    _.$router.push('roles/notifications')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        restrictStartDate(date) {
			return date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
		},
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        disableTime(date) {  
            return date < new Date();  
        },
        getRecipients() {
            let _ = this
            
            _.axios.get('/role')
                .then(resp => {
                    _.recipients = resp.data.data
                    if (_.$route.params.id){
                        _.show()
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        },
        customLabel( user ) {
            return user.name;
        }
    },
    mounted() {
        let _ = this
        _.getRecipients()
    }
}
</script>