<template>
    <section>
        <div class="row">
            <div class="col">
                <div class="input-group form-search-rounded mb-1">
                    <span class="input-group-prepend">
                        <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                            <i class="fa fa-search"></i>
                        </div>
                    </span> 
                    <input type="text" class="form-control bg-white border-left-0 border-right-0 border-top-0 no-shadow" 
                        placeholder="Search" v-model="search">
                </div>
            </div>
            <div class="col-lg-8 col-xs-12 text-right">
                <div class="btn-responsive">
                    <router-link to="/sponsors/banner/add" v-can="'admin'">
                        <button type="button" class="btn btn-primary">
                            <i class="fa fa-plus"></i> Tambah
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
        <p class="text-center" v-if="banners.data.length == 0">There is no data</p>
        <div class="card box-shadow" v-for="(banner, index) in banners.data" :key="index">
            <div class="card-body">
                <div class="row">
                    <div class="text-center align-self-center" style="border-right: 1px solid #f2f2f2; width: 128px;">
                        <button class="btn btn-sm btn-success" v-if="index != 0" @click="update_order(banner.id, 'up')"><i class="fa fa-arrow-up"></i></button> &nbsp;
                        <button class="btn btn-sm btn-danger" v-if="index != banners.total-1" @click="update_order(banner.id, 'down')"><i class="fa fa-arrow-down"></i></button>
                    </div>
                    <button class="btn btn-sm btn-rounded mr-2 ml-2 align-self-center" style="background-color:#45A8FF1A; height: fit-content;">
                        <span class="text-black font-600 f-16">{{ banner.order }}</span></button>
                    <img :src="banner.image" alt="" class="img-fluid pointer rounded align-self-center" style="width: 106px; height: 67px; object-fit:cover;" @click="lihat(banner.image)">
                    <div class="col">
                        <p class="f-16 font-600 mb-0">{{ banner.name }}</p>
                        <div class="row">
                            <div class="col-lg-5"><p class="f-12 text-secondary mb-0">Upload</p></div>
                            <div class="col"><p class="f-12 font-500 mb-0">: {{ banner.created_at | moment('D MMMM YYYY') }}</p></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5"><p class="f-12 text-secondary mb-0">Link Type</p></div>
                            <div class="col"><p class="f-12 font-500 mb-0">: {{ banner.link_type }}</p></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5"><p class="f-12 text-secondary mb-0">Waktu Tayang</p></div>
                            <div class="col"><p class="f-12 font-500 mb-0">: {{ banner.showtime | numFormat }} Detik</p></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5"><p class="f-12 text-secondary mb-0">Period</p></div>
                            <div class="col"><p class="f-12 font-500 mb-0">: {{ banner.start_date | moment('D MMMM YYYY') }} - {{ banner.end_date | moment('D MMMM YYYY') }}</p></div>
                        </div>
                    </div>
                    <div class="pr-1" v-can="'admin'">
                        <div class="btn-group mr-1" v-if="banner.is_active == true">
                            <button type="button" class="btn btn-sm dropdown-toggle btn-outline-primary"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Ubah Status">
                                Active
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" @click="update_status(banner.id, false)">Deactive</a>
                            </div>
                        </div>
                        <div class="btn-group mr-1" v-else>
                            <button type="button" class="btn btn-sm dropdown-toggle btn-outline-danger"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Ubah Status">
                                Deactive
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" @click="update_status(banner.id, true)">Active</a>
                            </div>
                        </div>
                        <router-link :to="'/sponsors/banner/edit/' + banner.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(banner)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <PaginateList :data="banners" v-if="banners.total != 0"/>
    </section>
</template>
<script>
import PaginateList from '@/components/PaginateList'

export default {
    components: {
        PaginateList
    },
    data() {
        return {
            search: '',
            banners: {
                data: [],
                total: 0
            },
            limit: 10
        }
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/sponsor/banner?limit=' + _.limit + '&key=' + _.search)
                .then(resp => {
                    _.banners = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        update_status( id, status ) {
            let _ = this
            _.axios.put('/sponsor/banner/' + id, { is_active: status })
                .then(() => {
                    _.index()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        update_order( id, direction ) {
            let _ = this
            _.axios.put('/sponsor/banner/reorder/' + id, { direction: direction })
                .then(() => {
                    _.index()
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Banner?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/sponsor/banner/' + x.id)
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded p-1'
                }
            })
        }
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    },
    mounted() {
        let _ = this
        _.index()
        _.$moment.locale('id')
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    }
}
</script>