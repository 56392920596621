var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v(_vm._s(_vm.$route.params.id ? 'Edit' : 'Tambah')+" Banner")]),_c('ValidationObserver',{ref:"observer",staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.store()}}},[_c('vue-dropzone',{ref:"myVueDropzone",staticClass:"mb-1",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-success":_vm.file_successfully_uploaded,"vdropzone-removed-file":function($event){_vm.model.image = null}}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('img',{attrs:{"src":"/assets/img/upload_image_dropzone.png","alt":""}}),_c('h3',{staticClass:"dropzone-custom-title f-16 font-600 text-primary"},[_vm._v("Drag and drop an image")]),_c('div',{staticClass:"subtitle f-12"},[_vm._v("or "),_c('span',{staticClass:"font-600 text-primary"},[_vm._v("browse")]),_vm._v(" to choose a file")])])]),_c('ValidationProvider',{attrs:{"name":"Nama Banner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Nama Banner")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Nama Banner"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Link")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.link_type),expression:"model.link_type"}],staticClass:"form-control",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "link_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("Pilih Link")]),_c('option',{attrs:{"value":"custom"}},[_vm._v("Custom")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),(_vm.model.link_type == 'search')?_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Search Keyword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Search Keyword")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.link_json.search_keyword),expression:"model.link_json.search_keyword"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Search Keyword"},domProps:{"value":(_vm.model.link_json.search_keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model.link_json, "search_keyword", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,2697270941)}),_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Lokasi")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Lokasi")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,961330814)}),_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Search Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Search Type")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Search Type")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,2362457374)}),_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Rating","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Rating")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Rating")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,1677506430)}),_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Warung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Warung")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Warung")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,4257815582)}),_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Promo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Promo")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Promo")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,2547420190)}),_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Urutan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Urutan")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.link_json.sort_by),expression:"model.link_json.sort_by"}],staticClass:"form-control",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model.link_json, "sort_by", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Urutan")]),_c('option',{attrs:{"value":"lowest_price"}},[_vm._v("Harga Terendah")]),_c('option',{attrs:{"value":"highest_price"}},[_vm._v("Harga Tertinggi")]),_c('option',{attrs:{"value":"diskon"}},[_vm._v("Diskon")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,837536131)})],1):_vm._e(),(_vm.model.link_type == 'warung')?_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Warung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Warung")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Warung")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,4257815582)})],1):_vm._e(),(_vm.model.link_type == 'produk')?_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Warung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Warung")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Warung")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,4257815582)}),_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Produk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Produk")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Produk")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,3763714046)})],1):_vm._e(),(_vm.model.link_type == 'kategori')?_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Kategori","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Kategori")]),_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Kategori")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,4249935966)})],1):_vm._e(),(_vm.model.link_type == 'kupon')?_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Kupon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Kupon")]),_c('div',{staticClass:"row pl-1 mt-1 mb-1"},[_c('div',{staticClass:"col-6 row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.link_json.kupon_type),expression:"model.link_json.kupon_type"}],staticClass:"align-self-center mr-1",attrs:{"type":"radio","value":"list"},domProps:{"checked":_vm._q(_vm.model.link_json.kupon_type,"list")},on:{"change":function($event){return _vm.$set(_vm.model.link_json, "kupon_type", "list")}}}),_c('label',{staticClass:"align-self-center mb-0",attrs:{"for":""}},[_vm._v("List Kupon")])]),_c('div',{staticClass:"col-6 row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.link_json.kupon_type),expression:"model.link_json.kupon_type"}],staticClass:"align-self-center mr-1",attrs:{"type":"radio","value":"detail"},domProps:{"checked":_vm._q(_vm.model.link_json.kupon_type,"detail")},on:{"change":function($event){return _vm.$set(_vm.model.link_json, "kupon_type", "detail")}}}),_c('label',{staticClass:"align-self-center mb-0",attrs:{"for":""}},[_vm._v("Detail Kupon")])])]),(_vm.model.link_json.kupon_type == 'detail')?_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Kupon")])]):_vm._e(),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,4121809062)})],1):_vm._e(),(_vm.model.link_type == 'custom')?_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-lg-6",attrs:{"name":"Custom Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Custom Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.link_json.custom_link),expression:"model.link_json.custom_link"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Custom Link"},domProps:{"value":(_vm.model.link_json.custom_link)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model.link_json, "custom_link", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,false,3826068253)})],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('ValidationProvider',{attrs:{"name":"Periode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Periode")]),_c('date-picker',{staticClass:"w-100",attrs:{"format":"DD/MM/YYYY","type":"date","value-type":"YYYY-MM-DD","placeholder":"Pilih Tanggal Mulai & Selesai","range":"","input-class":"form-control"},on:{"input":function($event){_vm.model.start_date = _vm.daterange_period[0]
                                            _vm.model.end_date   = _vm.daterange_period[1]}},model:{value:(_vm.daterange_period),callback:function ($$v) {_vm.daterange_period=$$v},expression:"daterange_period"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('ValidationProvider',{attrs:{"name":"Waktu Tayang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Waktu Tayang")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.showtime),expression:"model.showtime"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Contoh: 5"},domProps:{"value":(_vm.model.showtime)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "showtime", $event.target.value)}}})]),_c('div',{staticClass:"col align-self-center"},[_vm._v(" Detik ")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1),_c('div',{staticClass:"col-lg-4"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Status")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.is_active),expression:"model.is_active"}],staticClass:"form-control",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "is_active", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("Pilih Status")]),_c('option',{domProps:{"value":true}},[_vm._v("Aktif")]),_c('option',{domProps:{"value":false}},[_vm._v("Tidak Aktif")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1)]),_c('ValidationProvider',{attrs:{"name":"Deskripsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"required",attrs:{"for":""}},[_vm._v("Deskripsi")]),_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])}),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn text-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan "),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }