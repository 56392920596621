<template>
    <div class="card">
        <div class="card-body">
            <h3 class="font-600 mb-1">Setting</h3>
            <ul class="nav nav-tabs mt-1" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="pembeli-tab" data-toggle="tab" href="#pembeli" role="tab" aria-controls="pembeli" aria-selected="true">
                        FAQ Pembeli
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true">
                        Setting General
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="pembeli" role="tabpanel" aria-labelledby="pembeli-tab">
                    <FAQPembeli/>
                </div>
                <div class="tab-pane fade show" id="general" role="tabpanel" aria-labelledby="general-tab">
                    <General/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FAQPembeli from '@/views/setting/faq_pembeli/Index.vue'
import General from '@/views/setting/general/Index.vue'

export default {
    components: {
        FAQPembeli,
        General
    }
}
</script>