<template>
    <section>
        <ul>
            <li>
                <p class="f-16 font-500">Display Jarak <span class="text-primary font-600 f-14 pointer" data-toggle="modal" data-target="#modalDistances">Edit</span></p>
                <div class="row pl-1">
                    <div class="form-group mr-1 pr-1 mb-0" style="border-right: 1px solid #f2f2f2" v-for="data in model.distances" :key="data.distance">
                        <label for="" class="f-14 font-700">{{data.distance}} Km 
                            <span class="text-blue" v-if="data.distance == model.active_distance">(Default)</span>
                            <div class="badge badge-sm badge-primary" v-if="data.is_active">Active</div>
                            <div class="badge badge-sm badge-danger" v-else>Not Active</div>
                        </label>
                    </div>
                </div>
            </li>
            <hr>
            <li>
                <p class="f-16 font-500">Versi & Deskripsi <span class="text-primary font-600 f-14 pointer" data-toggle="modal" data-target="#modalVersionAndDescription">Edit</span></p>
                <p class="f-14">{{ model.version_and_description ? model.version_and_description : '-'}}</p>
            </li>
            <hr>
            <li>
                <p class="f-16 font-500">Nomor Telepon & Email Udadi <span class="text-primary font-600 f-14 pointer" data-toggle="modal" data-target="#modalPhoneAndEmail">Edit</span></p>
                <div class="row pl-1">
                    <div class="form-group mr-1 pr-1 mb-0" style="border-right: 1px solid #f2f2f2">
                        <label for="" class="f-12 text-secondary">Nomor Telepon</label>
                        <p class="f-14 font-700 text-primary mb-0">{{ model.phone_number ? model.phone_number : '-' }}</p>
                    </div>
                    <div class="form-group mb-0">
                        <label for="" class="f-12 text-secondary">Email</label>
                        <p class="f-14 font-700 text-primary mb-0">{{ model.email ? model.email : '-' }}</p>
                    </div>
                </div>
            </li>
            <hr>
            <li>
                <p class="f-16 font-500">Fasilitas <span class="text-primary font-600 f-14 pointer" data-toggle="modal" data-target="#modalFacilities">Edit</span></p>
                <div class="col-3 p-0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Fasilitas</th>
                            </tr>
                        </thead>
                        <tbody v-if="model.facilities.length > 0">
                            <tr v-for="(data, index) in model.facilities" :key="index">
                                <td>{{ index+1 }}</td>
                                <td class="text-left">{{ data.name }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="2">Tidak ada data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </li>
            <hr>
            <li>
                <p class="f-16 font-500">Jam Pengantaran <span class="text-primary font-600 f-14 pointer" data-toggle="modal" data-target="#modalDeliveryTimes">Edit</span></p>
                <div class="col-3 p-0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Nama</th>
                                <th>Waktu</th>
                            </tr>
                        </thead>
                        <tbody v-if="model.delivery_times.length > 0">
                            <tr v-for="(data, index) in model.delivery_times" :key="index">
                                <td>{{ index+1 }}</td>
                                <td class="text-left">{{ data.name }}</td>
                                <td><span class="f-12 text-blue" style="white-space: nowrap;">{{ data.start_time }} - {{ data.end_time }}</span></td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="3">Tidak ada data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </li>
        </ul>

        <div class="modal fade text-left show" id="modalDistances" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body pb-0">
                        <p class="f-16 font-500">Setting Display Jarak</p>
                        <div v-for="data in model.distances" :key="data.distance">
                            <div class="row mb-1">
                                <div class="col-4 align-self-center">
                                    {{ data.distance }} KM
                                </div>
                                <div class="col-4 align-self-center text-center">
                                    <input type="radio" v-model="model.active_distance" :value="data.distance"> 
                                    <span style="vertical-align: top;"> Default</span>
                                </div>
                                <div class="col-4 align-self-center text-right">
                                    <div class="btn-group" v-if="data.is_active == true">
                                        <button type="button" class="btn btn-sm dropdown-toggle btn-outline-primary"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Ubah Status">
                                            Active
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item">Deactive</a>
                                        </div>
                                    </div>
                                    <div class="btn-group" v-else>
                                        <button type="button" class="btn btn-sm dropdown-toggle btn-outline-danger"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Ubah Status">
                                            Deactive
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item">Active</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-dismiss="modal" class="btn text-primary waves-effect waves-light w-50 closeModal">Batal</button>
                        <button type="submit" class="btn btn-primary waves-effect waves-light w-50" @click="submit()">Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalVersionAndDescription" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body pb-0">
                        <p class="f-16 font-500">Setting Versi & Deskripsi</p>
                        <div class="form-group">
                            <label for="">Deskripsi</label>
                            <textarea name="" id="" cols="30" rows="6" class="form-control" placeholder="Masukkan deskripsi Anda" v-model="model.version_and_description"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-dismiss="modal" class="btn text-primary waves-effect waves-light w-50 closeModal">Batal</button>
                        <button type="submit" class="btn btn-primary waves-effect waves-light w-50" @click="submit()">Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalPhoneAndEmail" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body pb-0">
                        <p class="f-16 font-500">Setting Nomor Telepon & Email Udadi</p>
                        <div class="form-group">
                            <label for="">Nomor Telepon</label>
                            <input type="text" class="form-control" v-model="model.phone_number">
                        </div>
                        <div class="form-group">
                            <label for="">Email</label>
                            <input type="email" class="form-control" v-model="model.email">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-dismiss="modal" class="btn text-primary waves-effect waves-light w-50 closeModal">Batal</button>
                        <button type="submit" class="btn btn-primary waves-effect waves-light w-50" @click="submit()">Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalFacilities" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body pb-0">
                        <p class="f-16 font-500">Setting Fasilitas</p>
                        <div class="form-group" v-for="(facility, index) in model.facilities" :key="index">
                            <label for="">Fasilitas {{ index+1 }}</label>
                            <div class="row">
                                <div class="col-11">
                                    <input type="text" class="form-control" v-model="facility.name">
                                </div>
                                <button class="btn btn-rounded btn-danger align-self-center" style="height: fit-content;" @click="deleteFacility(index)"><i class="fa fa-trash"></i></button>
                            </div>
                        </div>
                        <button class="btn text-primary font-700 f-12 pl-0" @click="addFacility()">+ Tambah Fasilitas</button>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-dismiss="modal" class="btn text-primary waves-effect waves-light w-50 closeModal">Batal</button>
                        <button type="submit" class="btn btn-primary waves-effect waves-light w-50" @click="submit()">Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalDeliveryTimes" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body pb-0">
                        <p class="f-16 font-500">Setting Jam Pengantaran</p>
                        <div class="row" v-for="(data, index) in model.delivery_times" :key="index">
                            <div class="col-5">
                                <div class="form-group">
                                    <label for="">Nama Pengantaran {{ index+1 }}</label>
                                    <input type="text" class="form-control" placeholder="Ex: Pagi" v-model="data.name">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Mulai</label>
                                    <input type="text" class="form-control" placeholder="Ex: 07:00" v-model="data.start_time">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Akhir</label>
                                    <input type="text" class="form-control" placeholder="Ex: 11:00" v-model="data.end_time">
                                </div>
                            </div>
                            <button class="btn btn-rounded btn-danger align-self-center" style="height: fit-content;" @click="deleteDeliveryTime(index)"><i class="fa fa-trash"></i></button>
                        </div>
                        <button class="btn text-primary font-700 f-12 pl-0" @click="addDeliveryTime()">+ Tambah Jam</button>
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-dismiss="modal" class="btn text-primary waves-effect waves-light w-50 closeModal">Batal</button>
                        <button type="submit" class="btn btn-primary waves-effect waves-light w-50" @click="submit()">Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            model: {
                active_distance: 1,
                distances: [
                    {
                        distance: 1,
                        is_active: true
                    },
                    {
                        distance: 2,
                        is_active: true
                    },
                    {
                        distance: 3,
                        is_active: true
                    },
                    {
                        distance: 4,
                        is_active: true
                    },
                    {
                        distance: 5,
                        is_active: true
                    },
                ],
                version_and_description: null,
                phone_number: null,
                email: null,
                facilities: [],
                delivery_times: [],
            }
        }
    },
    methods: {
        getSetting() {
            let _ = this
            _.axios.get('/setting/general')
                .then( resp => {
                    if ( resp.data.data ) {
                        _.model = resp.data.data
                    }
                })
                .catch( err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        addFacility() {
            this.model.facilities.push({name: ''})
        },
        deleteFacility( id ) {
            this.model.facilities.splice(id, 1);
        },
        addDeliveryTime() {
            this.model.delivery_times.push({name: '', start_time: '', end_time: ''})
        },
        deleteDeliveryTime( id ) {
            this.model.delivery_times.splice(id, 1);
        },
        submit() {
            let _ = this
            let data = {
                ..._.model,
                distances: JSON.stringify(_.model.distances),
                facilities: JSON.stringify(_.model.facilities),
                delivery_times: JSON.stringify(_.model.delivery_times),
            }
            _.axios.post('/setting/general', data)
                .then( resp => {
                    console.log( resp.data )
                    window.$('.closeModal').click()
                    _.getSetting()
                })
                .catch( err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        }
    },
    mounted() {
        this.getSetting()
    },
}
</script>