<template>
    <section>
        <div class="row row-cols-5">
            <div class="col-lg-3 col-6">
                <div class="card">
                    <div class="card-body">
                        <img src="/assets/img/dashboard/jumlah_transaksi.png" alt="" class="img-fluid mb-2">
                        <p class="f-24 font-700">{{ transaksi_hari_ini | numFormat }}</p>
                        <p class="f-14 f-400 mb-0">Jumlah Transaksi Hari Ini</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="card">
                    <div class="card-body">
                        <img src="/assets/img/dashboard/total_penjualan.png" alt="" class="img-fluid mb-2">
                        <p class="f-24 font-700">{{ penjualan_hari_ini | currency }}</p>
                        <p class="f-14 f-400 mb-0">Total Penjualan Hari Ini</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="card">
                    <div class="card-body">
                        <img src="/assets/img/dashboard/jumlah_transaksi.png" alt="" class="img-fluid mb-2">
                        <p class="f-24 font-700">{{ total_produk_terjual | numFormat }}</p>
                        <p class="f-14 f-400 mb-0">Total Produk Terjual</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col">
                <div class="card">
                    <div class="card-body">
                        <img src="/assets/img/dashboard/kilogram.png" alt="" class="img-fluid mb-2">
                        <p class="f-24 font-700">{{ kilogram | numFormat }}</p>
                        <p class="f-14 f-400 mb-0">Kilo Gram</p>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-3 col-6">
                <div class="card">
                    <div class="card-body">
                        <img src="/assets/img/dashboard/jumlah_transaksi.png" alt="" class="img-fluid mb-2">
                        <div class="row">
                            <div class="col-4 text-center" style="overflow-wrap: normal;">
                                <p class="f-24 font-700">
                                    {{ rating.quality }} <img src="/assets/img/star.png" alt="" style="vertical-align: bottom">
                                </p>
                                Kualitas
                            </div>
                            <div class="col-4 text-center" style="overflow-wrap: normal;">
                                 <p class="f-24 font-700">
                                    {{ rating.service }} <img src="/assets/img/star.png" alt="" style="vertical-align: bottom">
                                </p>
                                Layanan
                            </div>
                            <div class="col-4 text-center" style="overflow-wrap: normal;">
                                 <p class="f-24 font-700">
                                    {{ rating.price }} <img src="/assets/img/star.png" alt="" style="vertical-align: bottom">
                                </p>
                                Harga
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p>
                    <img src="/assets/img/dashboard/jumlah_transaksi_no.png" alt="" class="img-fluid align-bottom">
                    <span class="f-24 font-600 ml-1">Grafik Jumlah Transaksi Hari Ini</span>
                </p>
                <div class="card mt-2">
                    <div class="card-body">
                        <i class="fa fa-calendar mr-1"></i> {{today | moment('dddd, DD MMMM YYYY')}}
                        <apexchart width="100%" type="bar" height='300px' :options="barOptions" :series="barSeries" v-if="showBar"></apexchart>
                        <div class="text-center" style="height:315px; font-size: 50px; padding-top: 100px;" v-else>
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <p>
                    <img src="/assets/img/dashboard/jumlah_transaksi_no.png" alt="" class="img-fluid align-bottom">
                    <span class="f-24 font-600 ml-1">Histori Grafik Jumlah Transaksi </span>
                </p>
                <div class="card mt-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="">Periode</label>
                                    <date-picker
                                        v-model="daterange_period"
                                        format="DD/MM/YYYY"
                                        type="date"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Pilih Tanggal Awal & Akhir"
                                        range
                                        input-class="form-control"
                                        class="w-100">
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                        <apexchart width="100%" type="line" height='238px' :options="lineOptions" :series="lineSeries" v-if="showLine"></apexchart>
                        <div class="text-center" style="height:253px; font-size: 50px; padding-top: 100px;" v-else>
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="row">
                <div class="col-4 pr-0" v-if="showSidebarMap">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="">Kota / Kabupaten</label>
                            <gmap-autocomplete
                                @place_changed="set_kordinat($event)"
                                placeholder="Cari Kota / Kabupaten..."
                                class="form-control"
                                ref="mapp">
                            </gmap-autocomplete>
                        </div>
                        <p class="f-16">Jumlah Pembeli: <b>{{ mapInformation.total_pembeli | numFormat }}</b></p>
                        <div class="row">
                            <div class="ml-1 mr-1">
                                <div style="background: rgba(107, 127, 250, 0.12);border-radius: 16px;color:#6B7FFA;padding:5px 27px;" class="text-center">
                                    <div class="row justify-content-center align-items-center">
                                        <div style="width:12px;height:12px;border-radius:50%;background:#6B7FFA;margin-right:8px;"></div> Pembeli Aktif
                                    </div>
                                </div>
                                <p class="mt-1 text-center f-24 font-700">{{ mapInformation.total_pembeli_aktif | numFormat }}</p>
                            </div>
                            <div class="">
                                <div style="background: #FFE3DD;border-radius: 16px;color:#FA674A;padding:5px 27px;" class="text-center">
                                    <div class="row justify-content-center align-items-center">
                                        <div style="width:12px;height:12px;border-radius:50%;background:#FA674A;margin-right:8px;"></div> Pembeli Nonaktif
                                    </div>
                                </div>
                                <p class="mt-1 text-center f-24 font-700">{{ mapInformation.total_pembeli_nonaktif | numFormat }}</p>
                            </div>
                        </div>
                        <hr>
                        <p class="f-16">Jumlah Penjual: <b>{{ mapInformation.total_penjual | numFormat }}</b></p>
                        <div class="row">
                            <div class="ml-1 mr-1">
                                <div style="background: rgba(107, 127, 250, 0.12);border-radius: 16px;color:#6B7FFA;padding:5px 27px;" class="text-center">
                                    <div class="row justify-content-center align-items-center">
                                        <div style="width:12px;height:12px;border-radius:50%;background:#6B7FFA;margin-right:8px;"></div> Penjual Aktif
                                    </div>
                                </div>
                                <p class="mt-1 text-center f-24 font-700">{{ mapInformation.total_penjual_aktif | numFormat }}</p>
                            </div>
                            <div class="">
                                <div style="background: #FFE3DD;border-radius: 16px;color:#FA674A;padding:5px 27px;" class="text-center">
                                    <div class="row justify-content-center align-items-center">
                                        <div style="width:12px;height:12px;border-radius:50%;background:#FA674A;margin-right:8px;"></div> Penjual Nonaktif
                                    </div>
                                </div>
                                <p class="mt-1 text-center f-24 font-700">{{ mapInformation.total_penjual_nonaktif | numFormat }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" :class="showSidebarMap ? 'pl-0' : ''">
                    <img src="/assets/img/arrow-right.png" alt="" class="img-fluid pointer" style="position: absolute;z-index: 1;top: 42%;" 
                        @click="showSidebarMap == true ? showSidebarMap = false : showSidebarMap = true">
                    <GmapMap
                        class = "maps"
                        style = "width:100%;height: 600px"
                        :center = map.center
                        :options = map.options 
                        :zoom = map.zoom>
                        <gmap-marker
                            v-for="(item, index) in map.markers"
                            :key="index"
                            :position="item"
                            :icon="{ url: '../assets/img/marker.png'}"/>
                    </GmapMap>
                </div>                
            </div>
        </div>        
    </section>
</template>

<script>
import Vue from 'vue'

export default {
    data() {
        return {
            showSidebarMap: false,
            transaksi_hari_ini: 0,
            penjualan_hari_ini: 0,
            total_produk_terjual: 0,
            kilogram: 0,
            udadi_moms: 0,
            grafik_jumlah_transaksi_hari_ini: {},
            today: new Date(),
            daterange_period: null,
            barOptions: {
                chart: {
                    id: 'vuechart-example',
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                annotations: {
                    points: [
                        {
                            x: 'Belum Bayar',
                            label: {
                                borderColor: 'none',
                                style: {
                                    color: '#000',
                                    background: '#FFF',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                },
                                text: '',
                            }
                        },
                        {
                            x: 'Dikemas',
                            label: {
                                borderColor: 'none',
                                style: {
                                    color: '#000',
                                    background: '#FFF',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                },
                                text: '',
                            }
                        },
                        {
                            x: 'Dikirim',
                            label: {
                                borderColor: 'none',
                                style: {
                                    color: '#000',
                                    background: '#FFF',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                },
                                text: '',
                            }
                        },
                        {
                            x: 'Selesai',
                            label: {
                                borderColor: 'none',
                                style: {
                                    color: '#000',
                                    background: '#FFF',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                },
                                text: '',
                            }
                        }
                    ]
                },
                xaxis: {
                    categories: ['Belum Bayar', 'Dikemas', 'Dikirim', 'Selesai'],
                },
                colors: ['#ED8B33', '#8E42D5', '#2F93EC','#2BA379'],
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        distributed: true
                    }
                },
                dataLabels: {
                    style: {
                        fontSize: '12px',
                        colors: ["#FFFFFF"]
                    }
                },
                legend: {
                    show: false,
                },
                grid: {
                    show: false,
                }
            },
            barSeries: [
                {
                    name: 'Jumlah',
                    data: [],
                }
            ],
            lineOptions: {
                chart: {
                    id: 'line-vuechart',
                },
                xaxis: {
                    categories: [],
                },
                stroke: {
                    width: 2
                },
                colors: ['#2BA379']
            },
            lineSeries: [
                {
                    name: 'Total',
                    data: [],
                }
            ],
            showBar: false,
            showLine: false,
            rating: {
                quality: 0,
                service: 0,
                price: 0,
            },
            map: {
                center: { lat: -2.2145852388265177, lng: 117.104081069627 },
                zoom: 4,
                markers: [],
                place: '',
                options: {
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false
                },
			},
            penjuals: [],
            mapInformation: {
                total_pembeli: 0,
                total_pembeli_aktif: 0,
                total_pembeli_nonaktif: 0,
                total_penjual: 0,
                total_penjual_aktif: 0,
                total_penjual_nonaktif: 0,
            }
        }
    },
    methods: {
        get_transaksi_hari_ini()
        {
            let _ = this
            _.axios.get('/dashboard/transaksi_hari_ini')
                .then( resp => {
                    _.transaksi_hari_ini = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        get_penjualan_hari_ini()
        {
            let _ = this
            _.axios.get('/dashboard/penjualan_hari_ini')
                .then( resp => {
                    _.penjualan_hari_ini = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        get_total_produk_terjual()
        {
            let _ = this
            _.axios.get('/dashboard/total_produk_terjual')
                .then( resp => {
                    _.total_produk_terjual = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        get_kilogram()
        {
            let _ = this
            _.axios.get('/dashboard/kilogram')
                .then( resp => {
                    _.kilogram = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        get_rating()
        {
            let _ = this
            _.axios.get('/dashboard/rating')
                .then( resp => {
                    _.rating = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        get_grafik_jumlah_transaksi_hari_ini()
        {
            let _ = this
            _.axios.get('/dashboard/grafik_jumlah_transaksi_hari_ini')
                .then( resp => {
                    _.grafik_jumlah_transaksi_hari_ini = resp.data.data

                    _.barOptions.annotations.points[0].label.text = _.currencyFormat(_.grafik_jumlah_transaksi_hari_ini.belum_bayar.total)
                    _.barOptions.annotations.points[1].label.text = _.currencyFormat(_.grafik_jumlah_transaksi_hari_ini.dikemas.total)
                    _.barOptions.annotations.points[2].label.text = _.currencyFormat(_.grafik_jumlah_transaksi_hari_ini.dikirim.total)
                    _.barOptions.annotations.points[3].label.text = _.currencyFormat(_.grafik_jumlah_transaksi_hari_ini.selesai.total)
                    
                    _.barSeries[0].data.push(_.grafik_jumlah_transaksi_hari_ini.belum_bayar.count)
                    _.barSeries[0].data.push(_.grafik_jumlah_transaksi_hari_ini.dikemas.count)
                    _.barSeries[0].data.push(_.grafik_jumlah_transaksi_hari_ini.dikirim.count)
                    _.barSeries[0].data.push(_.grafik_jumlah_transaksi_hari_ini.selesai.count)

                    _.showBar = true
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.showBar = true
                })
        },
        get_line_jumlah_transaksi()
        {
            let _ = this
            _.showLine = false
            _.axios.get('/dashboard/line_jumlah_transaksi' 
                + '?start_date=' + (_.daterange_period != null ? _.daterange_period[0] : '') 
                + '&end_date=' +  (_.daterange_period != null ? _.daterange_period[1] : ''))
                .then( resp => {
                    if (resp.data.data) {
                        _.line_jumlah_transaksi = resp.data.data
                        _.lineOptions.xaxis.categories = _.line_jumlah_transaksi.map(x => x.date)
                        _.lineSeries[0].data = _.line_jumlah_transaksi.map(x => x.total)
                    }
                    _.showLine = true
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.showLine = true
                })
        },
        currencyFormat(nominal)
        {
            return new Intl.NumberFormat("id-ID", 
                { style: "currency", currency: "IDR" })
                .format(nominal)
        },
        get_penjual() {
            let _ = this
            _.axios.get('/user?role=penjual')
                .then(resp=>{
                    _.penjuals = resp.data.data

                    _.penjuals.forEach(penjual => {
                        let latLng = {
                            lat: parseFloat(penjual.lat),
                            lng: parseFloat(penjual.lng),
                        }

                        _.map.markers.push( latLng )
                    });

                    _.map.center = _.map.markers[0]
                    _.map.zoom = 15
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        set_kordinat(place)
        {
		    let _ = this
            
            let latLng = {
                lat: parseFloat(place.geometry.location.lat()),
                lng: parseFloat(place.geometry.location.lng()),
            }

            Vue.$geocoder.send(latLng,
                response => {
                    var filtered_array = response.results[0].address_components.filter(function(address_component){
                        return address_component.types.includes("administrative_area_level_2");
                    });
                    let city = filtered_array.length ? filtered_array[0].long_name: "";

                    _.axios.get('/dashboard/map?kota_kabupaten=' + city)
                        .then( resp => {
                            _.mapInformation = resp.data.data
                        })
                })
		},
    },
    async mounted()
    {
        let _ = this
        _.$moment.locale('id')

        await _.get_transaksi_hari_ini()
        await _.get_penjualan_hari_ini()
        await _.get_total_produk_terjual()
        await _.get_kilogram()
        await _.get_rating()
        await _.get_grafik_jumlah_transaksi_hari_ini()
        await _.get_line_jumlah_transaksi()
        await _.get_penjual()
    },
    watch: {
        daterange_period: function()
        {
            this.get_line_jumlah_transaksi()
        }
    }
}
</script>
