<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Promo</h3>                        
                <ValidationObserver class="card-content" ref="observer">
                    <form @submit.prevent="store()">
                        <div class="row">
                            <div class="col-lg-6">
                                <ValidationProvider name="Nama Promo" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Nama Promo</label>
                                        <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan Nama Promo">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Periode" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Periode</label>
                                        <date-picker
                                            v-model="daterange_period"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih Tanggal Mulai & Selesai"
                                            range
                                            input-class="form-control"
                                            class="w-100"
                                            @input="
                                                model.start_date = daterange_period[0]
                                                model.end_date   = daterange_period[1]">
                                        </date-picker>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Jumlah Potongan" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Jumlah Potongan</label>
                                        <div class="row pl-1 mt-1 mb-1">
                                            <div class="col-3 row">
                                                <input type="radio" class="align-self-center mr-1" value="rupiah" v-model="model.discount_type">
                                                <label for="" class="align-self-center mb-0">Rupiah</label>
                                            </div>
                                            <div class="col-3 row">
                                                <input type="radio" class="align-self-center mr-1" value="persen" v-model="model.discount_type">
                                                <label for="" class="align-self-center mb-0">Persen</label>
                                            </div>
                                        </div>

                                        <vue-numeric class="form-control" placeholder="Masukkan Jumlah Potongan" currency="Rp" separator="."
                                            v-model="model.discount_amount" v-if="model.discount_type == 'rupiah'"></vue-numeric>
                                        <vue-numeric class="form-control" placeholder="Masukkan Jumlah Potongan" currency="%" separator="."
                                            v-model="model.discount_amount" v-else-if="model.discount_type == 'persen'" 
                                            currency-symbol-position="suffix"></vue-numeric>

                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required" >
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="required">Deskripsi</label>
                                        <quill-editor v-model="model.description" :options="editorOption"></quill-editor>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-lg-6">
                                <ValidationProvider name="Produk" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Pilih Produk</label>
                                        <multiselect 
                                            v-model="selected_products"
                                            label="name"
                                            track-by="id" 
                                            placeholder="Pilih Produk"
                                            :options="products"
                                            :multiple="true" 
                                            :clear-on-select="false" 
                                            :close-on-select="false">
                                            <span slot="noResult">Oops! Data not found.</span>
                                        </multiselect>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Status</label>
                                        <select name="" id="" class="form-control" v-model="model.is_active">
                                            <option :value="null">Pilih Status</option>
                                            <option :value="true">Aktif</option>
                                            <option :value="false">Tidak Aktif</option>
                                        </select>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="text-right">
                            <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                            <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
const toolbarOptions = [
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['clean'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['blockquote', 'code-block']
]
export default {
    data() {
        return {
            model: {
                type: 'produk',
                name: null,
                start_date: null,
                end_date: null,
                discount_type: 'rupiah',
                discount_amount: 0,
                description: null,
                is_active: null,
                products: []
            },
            daterange_period: null,
            isLoading: false,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    },
                },
                placeholder: "Masukkan Deskripsi"
            },
            selected_products: [],
            products: []
        }
    },
    methods: {
        get_product() {
            let _ = this
            _.axios.get('/product')
                .then(resp => {
                    _.products = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        show() {
            let _ = this
            _.axios.get('/promo/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.selected_products = _.model.products.map(x => x.product)
                    _.daterange_period = [ _.$moment(_.model.start_date).format('YYYY-MM-DD'), _.$moment(_.model.end_date).format('YYYY-MM-DD') ]
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.$route.params.id) {
                    _.update()
                    return
                }

                let data = {
                    ..._.model,
                    products: _.selected_products.map(x => x.id)
                }
                _.axios.post('/promo', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.$router.push('/promos')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this
            let data = {
                ..._.model,
                products: _.selected_products.map(x => x.id)
            }
            _.axios.put('/promo/' + _.$route.params.id, data)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.$router.push('/promos')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        }
    },
    mounted() {
        let _ = this

        _.get_product()
        if (_.$route.params.id){
            _.show()
        }
    }
}
</script>