<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/vuexy-login-bg.jpg" class="img-fluid" alt="">
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form">
                <div class="form-logo">
                    <img src='/assets/img/logo.png' class="img-fluid" alt="">
                </div>

                <p class="mb-0 f-18 font-600">Halo,</p>
                <p class="text-blue font-500 f-18">Selamat Datang</p>

                <form @submit.prevent="login()" class="mt-2">
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                        <div class="col p-0">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <input type="text" id="name" class="form-control" required v-model="model.email" placeholder="Input Email">
                                <label class="form-control-placeholder" for="name">EMAIL</label>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                        <div class="col p-0">
                            <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                <input :type="showPassword ? 'text' : 'password'" id="password" class="form-control border-right-0" required v-model="model.password" placeholder="Input Password">
                                <label class="form-control-placeholder" for="password">PASSWORD</label>
                                <span class="input-group-append">
                                    <div class="input-group-text">
                                        <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                            <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                        </a>
                                    </div>
                                </span>
                            </div>
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>

                    <small class="float-right mt-1"><router-link to="/forgot-password" class="text-primary">Lupa Password?</router-link></small>
                    <small class="float-left mt-1"><input type="checkbox"> Remember Me</small>

                    <button type="submit" class="btn btn-primary waves-effect waves-light w-100 mt-2" :disabled="isLoading">
                        <div v-if="isLoading">Signing In <i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Sign In</div>
                    </button>

                    <div class="text-center mt-1" v-if="!isValid">
                        <div v-for="(v, k) in errors" :key="k">
                            <p v-for="error in v" :key="error" class="mb-0 val-error" v-html="error"></p>
                        </div>
                    </div>
                </form>                
            </div>            
        </ValidationObserver>  
    </div> 
</template>

<script>
export default {
    data() {
        return {
            model: {
                email: '',
                password: ''
            },
            isValid: true,
            isLoading: false,
            showPassword: false,
            errors: []
        }
    },
	methods: {
		async login() {
            let _ = this  
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                // window.location = '/'
                _.axios.post('/auth/login', _.model)
                    .then(resp=>{
                        _.$store.dispatch('login', resp.data.data)
                        window.location = '/'
                    })
                    .catch(err=>{
                        if (err.response.status == 404) {
                            _.errors = { 'account': ['Email not registered'] }
                        } else {
                            _.errors = err.response.data.errors
                        }
                        _.isValid = false
                        _.isLoading = false
                    })
            }
		}
	},
}
</script>