<template>
    <section>
        <Table 
            :dataTable="table.dataTable"
            :title="table.title">
            <template v-slot:header>
                <div class="row">
                    <div class="col">
                        <div class="input-group form-search-rounded mb-1">
                            <span class="input-group-prepend">
                                <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span> 
                            <input type="text" class="form-control bg-white border-left-0 border-right-0 border-top-0 no-shadow" 
                                placeholder="Search" v-model="search">
                        </div>
                    </div>
                    <div class="col-lg-8 col-xs-12 text-right">
                        <div class="btn-responsive">
                            <router-link to="/promos/product/add" v-can="'admin'">
                                <button type="button" class="btn btn-primary">
                                    <i class="fa fa-plus"></i> Tambah
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>                
            </template>

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/promos/product/detail/' + data.id">
                        <button type="button" class="btn btn-rounded btn-success waves-effect waves-light" title="Detail">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                    <router-link :to="'/promos/product/edit/' + data.id" v-can="'admin'">
                        <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                            <i class="fa fa-edit"></i>
                        </button>
                    </router-link>
                    <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)" v-can="'admin'">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template> 
        </Table>
    </section>
</template>

<script>
import Table from '@/components/Table.vue'

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                // title: 'Customer',
                dataTable: {
                    header: ['No', 'ID', 'Nama Promo', 'Jumlah Potongan', 'Periode', 'Status', 'Action'],
                    columns: ['counter', 'id', 'name', 'discount_amount', 'period', 'is_active'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false
		}
	},
    mounted() {
        let _ = this
        _.index()
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        index(page) {
            let _ = this
            _.axios.get('/promo?type=produk&key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Kupon?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/promo/' + x.id)
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>