<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Ubah Password</h3>
            <div class="row">
                <div class="col-lg-2 col-xs-12 text-center mb-1">
                    <button class="btn btn-rounded btn-primary p-1">
                        <span class="material-icons" style="font-size: 35px;">lock</span>
                    </button>
                </div>
                <div class="col align-self-center">
                    <p class="f-14">Untuk melindungi keamanan akun Anda, masukkan kata sandi Anda saat ini. Password baru akan digunakan untuk login, mohon masukkan minimal 6 karakter</p>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="isChange()">
                            <ValidationProvider name="Password saat ini" rules="required|min:6" v-slot="{ errors }">
                                <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                                    <input :type="show_old_password ? 'text' : 'password'" v-model="model.old_password" class="form-control border-right-0" placeholder="Masukkan password saat ini">
                                    <label class="form-control-placeholder" for="password">PASSWORD SAAT INI</label>
                                    <span class="input-group-append">
                                        <div class="input-group-text">
                                            <a href="javascript:;" @click="show_old_password = !show_old_password" class="text-blue">
                                                <i :class="show_old_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                            </a>
                                        </div>
                                    </span> 
                                </div>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </ValidationProvider>
                            <hr>
                            <ValidationProvider name="Password baru" rules="required|min:6" v-slot="{ errors }">
                                <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                                    <input :type="show_new_password ? 'text' : 'password'" v-model="model.new_password" class="form-control border-right-0" placeholder="Masukkan password baru" ref="Password baru">
                                    <label class="form-control-placeholder" for="password">PASSWORD BARU</label>
                                    <span class="input-group-append">
                                        <div class="input-group-text">
                                            <a href="javascript:;" @click="show_new_password = !show_new_password" class="text-blue">
                                                <i :class="show_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                            </a>
                                        </div>
                                    </span> 
                                </div>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </ValidationProvider>
                            <ValidationProvider name="Konfirmasi password baru" rules="required|confirmed:Password baru|min:6" v-slot="{ errors }">
                                <div class="form-group input-group mb-0 mt-2" :class="{ 'validation-warning': errors[0] }">                            
                                    <input :type="show_conf_new_password ? 'text' : 'password'" v-model="model.conf_new_password" class="form-control border-right-0" placeholder="Masukkan konfirmasi password baru" data-vv-as="Password baru">
                                    <label class="form-control-placeholder" for="password">KONFIRMASI PASSWORD BARU</label>
                                    <span class="input-group-append">
                                        <div class="input-group-text">
                                            <a href="javascript:;" @click="show_conf_new_password = !show_conf_new_password" class="text-blue">
                                                <i :class="show_conf_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                            </a>
                                        </div>
                                    </span> 
                                </div>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </ValidationProvider>
                            <p class="mt-1"><small><b>Petunjuk</b>: <br>Kata sandi harus setidaknya 6 karakter. Untuk membuatnya lebih kuat, gunakan huruf besar dan kecil, angka, dan simbol seperti ! " ? $% ^ )</small></p>
                            <div class="text-right">
                                <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                                <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Simpan</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                old_password: null,
                new_password: null,
                conf_new_password: null
            },
            show_old_password: false,
            show_new_password: false,
            show_conf_new_password: false,
            isLoading: false,
            state: 2
        }
    },
    methods: {
        save() {
            let _ = this
            _.isLoading = true
            _.axios.post('auth/change_password', _.model)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    
                    _.$store.dispatch('logout')
                    window.location = '/login'
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors,
                    })
                    _.isLoading = false
                })
        },
        async isChange(){
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.$swal.fire({
                    title: 'Apakah Anda yakin ingin mengubah kata sandi Anda?',
                    html: '<p class="popup-text-normal">Anda akan keluar secara otomatis ketika Anda mengubah kata sandi Anda.</p>',
                    icon: 'question',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonColor: '#0036A0',
                    confirmButtonText: 'Lanjutkan',
                    }).then((result) => {
                        if (result.value) {
                            _.save()
                        }
                    })
            }
        }
    }
}
</script>