<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col">
                            <div class="input-group form-search-rounded mb-1">
                                <span class="input-group-prepend">
                                    <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span> 
                                <input type="text" class="form-control bg-white border-left-0 border-right-0 border-top-0 no-shadow" 
                                    placeholder="Search" v-model="search">
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <span class="mb-0 align-self-center">Filter Status Transaksi</span>
                                <div class="col">
                                    <select class="form-control" v-model="filter">
                                        <option value="">Semua Status</option>
                                        <option value="waiting">Belum Bayar</option>
                                        <option value="packing">Dikemas</option>
                                        <option value="on_process">Dikirim</option>
                                        <option value="finish">Selesai</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col col-xs-12 text-right">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-secondary" @click="download()" :disabled="table.dataTable.lists.data.length == 0">
                                    <div v-if="isLoading">Downloading <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else><i class="fa fa-download"></i> Export</div>
                                </button>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Kode Transaksi</th>
                            <th>Waktu Transaksi</th>
                            <th>Status</th>
                            <th>Nama Penjual</th>
                            <th>Nama Pembeli</th>
                            <th>Jumlah Produk</th>
                            <th>Total</th>
                            <th>Metode Pembayaran</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block">
                        <router-link :to="'/transactions/detail/' + data.id">
                            <button type="button" class="btn btn-rounded btn-success waves-effect waves-light" title="Detail">
                                <i class="fa fa-eye"></i>
                            </button>
                        </router-link>
                    </div>
                </template> 
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Transaksi',
                dataTable: {
                    header: [],
                    columns: ['counter', 'order_id', 'created_at', 'shipping_status', 'penjual_name', 'customer_name', 'product_total', 'price_final', 'payment_method'],
                    lists: [],
                }
			},
            search: '',
            filter: '',
            limit: 10,
            isLoading: false
		}
	},
    mounted() {
        let _ = this
        _.index()
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        index(page) {
            let _ = this
            _.axios.get('/transaction?key=' + _.search + '&page=' + page + '&limit=' + _.limit + '&status=' + _.filter)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        download(){
            let _ = this
            _.isLoading = true
            _.axios.get('/transaction/export'
                    + '?key=' + _.search 
                    + '&status=' + _.filter
                ,{ responseType: 'arraybuffer' })
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Transaksi-'+ _.$moment().format('DDMMYYYYhhmmss') +'.xlsx'
                    link.click()
                    _.isLoading = false
                })
                .catch(() => {
                    _.isLoading = false
                })
        },
    },
    watch: {
        search: function() {
            this.index()
        },
        filter: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>