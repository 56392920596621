var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v(_vm._s(_vm.$route.params.id ? 'Edit' : 'Tambah')+" Notifikasi")]),_c('ValidationObserver',{ref:"observer",staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.store()}}},[_c('ValidationProvider',{attrs:{"name":"Judul Notifikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Judul Notifikasi")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Judul Notifikasi"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"Isi Notifikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"required",attrs:{"for":""}},[_vm._v("Isi Notifikasi")]),_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Waktu Publish Notifikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Waktu Publish Notifikasi")]),_c('div',{staticClass:"row pl-1 mt-1 mb-1"},[_c('div',{staticClass:"col-3 row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.publish_type),expression:"model.publish_type"}],staticClass:"align-self-center mr-1",attrs:{"type":"radio","value":"now"},domProps:{"checked":_vm._q(_vm.model.publish_type,"now")},on:{"change":function($event){return _vm.$set(_vm.model, "publish_type", "now")}}}),_c('label',{staticClass:"align-self-center mb-0",attrs:{"for":""}},[_vm._v("Sekarang")])]),_c('div',{staticClass:"col-3 row mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.publish_type),expression:"model.publish_type"}],staticClass:"align-self-center mr-1",attrs:{"type":"radio","value":"custom"},domProps:{"checked":_vm._q(_vm.model.publish_type,"custom")},on:{"change":function($event){return _vm.$set(_vm.model, "publish_type", "custom")}}}),_c('label',{staticClass:"align-self-center mb-0",attrs:{"for":""}},[_vm._v("Pilih Waktu")])])]),(_vm.model.publish_type == 'custom')?_c('div',{staticClass:"w-50"},[_c('ValidationProvider',{attrs:{"name":"Tanggal Publish","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-0",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Tanggal & Jam Publish")]),_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD MMMM YYYY, HH:mm WIB","type":"datetime","input-class":'form-control',"value-type":"YYYY-MM-DD HH:mm:ss","placeholder":"Pilih Tanggal & Jam Publish","time-picker-options":{start: '00:00', step:'00:01' , end: '23:30', format: 'HH:mm' },"disabled-date":_vm.disabledBeforeToday,"disabled-time":_vm.disableTime},model:{value:(_vm.model.publish_datetime),callback:function ($$v) {_vm.$set(_vm.model, "publish_datetime", $$v)},expression:"model.publish_datetime"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1):_vm._e(),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"Penerima Notifikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group w-50",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"required",attrs:{"for":""}},[_vm._v("Penerima Notifikasi")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.recipient_type),expression:"model.recipient_type"}],staticClass:"form-control",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "recipient_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Semua"}},[_vm._v("Semua")]),_c('option',{attrs:{"value":"Khusus"}},[_vm._v("Khusus")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),(_vm.model.recipient_type == 'Khusus')?_c('ValidationProvider',{attrs:{"name":"Recipient","rules":"required"}},[_c('div',{staticClass:"form-group w-50"},[_c('label',{staticClass:"form-label"},[_vm._v("Kepada")]),_c('multiselect',{attrs:{"id":"id","track-by":"id","placeholder":"Pilih penerima","options":_vm.recipients,"multiple":true,"custom-label":_vm.customLabel,"close-on-select":false,"loading":_vm.isLoading},model:{value:(_vm.selectedRecipients),callback:function ($$v) {_vm.selectedRecipients=$$v},expression:"selectedRecipients"}})],1)]):_vm._e(),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn text-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan "),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }