<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Banner</h3>                        
                <ValidationObserver class="card-content" ref="observer">
                    <form @submit.prevent="store()">
                        <vue-dropzone
                            ref="myVueDropzone" 
                            id="dropzone" 
                            :options="dropzoneOptions" 
                            class="mb-1" 
                            :useCustomSlot="true"
                            @vdropzone-success="file_successfully_uploaded"
                            @vdropzone-removed-file="model.image = null">
                            <div class="dropzone-custom-content">
                                <img src="/assets/img/upload_image_dropzone.png" alt="">
                                <h3 class="dropzone-custom-title f-16 font-600 text-primary">Drag and drop an image</h3>
                                <div class="subtitle f-12">or <span class="font-600 text-primary">browse</span> to choose a file</div>
                            </div>
                        </vue-dropzone>
                        <ValidationProvider name="Nama Banner" v-slot="{ errors }" rules="required">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Nama Banner</label>
                                <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan Nama Banner">
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Link" v-slot="{ errors }" rules="required">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Link</label>
                                <select name="" id="" class="form-control" v-model="model.link_type">
                                    <option :value="null">Pilih Link</option>
                                    <!-- <option value="search">Search</option>
                                    <option value="warung">Warung</option>
                                    <option value="produk">Produk</option>
                                    <option value="kategori">Kategori</option>
                                    <option value="kupon">Kupon</option> -->
                                    <option value="custom">Custom</option>
                                </select>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div v-if="model.link_type == 'search'" class="row">
                            <ValidationProvider class="col-lg-6" name="Search Keyword" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Search Keyword</label>
                                    <input type="text" v-model="model.link_json.search_keyword" class="form-control" placeholder="Masukkan Search Keyword">
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col-lg-6" name="Lokasi" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Lokasi</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Lokasi</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col-lg-6" name="Search Type" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Search Type</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Search Type</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col-lg-6" name="Rating" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Rating</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Rating</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col-lg-6" name="Warung" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Warung</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Warung</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col-lg-6" name="Promo" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Promo</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Promo</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col-lg-6" name="Urutan" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Urutan</label>
                                    <select name="" id="" v-model="model.link_json.sort_by" class="form-control">
                                        <option value="">Pilih Urutan</option>
                                        <option value="lowest_price">Harga Terendah</option>
                                        <option value="highest_price">Harga Tertinggi</option>
                                        <option value="diskon">Diskon</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="model.link_type == 'warung'" class="row">
                            <ValidationProvider class="col-lg-6" name="Warung" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Warung</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Warung</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="model.link_type == 'produk'" class="row">
                            <ValidationProvider class="col-lg-6" name="Warung" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Warung</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Warung</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col-lg-6" name="Produk" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Produk</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Produk</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="model.link_type == 'kategori'" class="row">
                            <ValidationProvider class="col-lg-6" name="Kategori" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Kategori</label>
                                    <select name="" id="" class="form-control">
                                        <option value="">Pilih Kategori</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="model.link_type == 'kupon'" class="row">
                            <ValidationProvider class="col-lg-6" name="Kupon" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Kupon</label>
                                    <div class="row pl-1 mt-1 mb-1">
                                        <div class="col-6 row">
                                            <input type="radio" class="align-self-center mr-1" value="list" v-model="model.link_json.kupon_type">
                                            <label for="" class="align-self-center mb-0">List Kupon</label>
                                        </div>
                                        <div class="col-6 row">
                                            <input type="radio" class="align-self-center mr-1" value="detail" v-model="model.link_json.kupon_type">
                                            <label for="" class="align-self-center mb-0">Detail Kupon</label>
                                        </div>
                                    </div>

                                    <select name="" id="" class="form-control" v-if="model.link_json.kupon_type == 'detail'">
                                        <option value="">Pilih Kupon</option>
                                    </select>
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="model.link_type == 'custom'" class="row">
                            <ValidationProvider class="col-lg-6" name="Custom Link" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Custom Link</label>
                                    <input type="text" v-model="model.link_json.custom_link" class="form-control" placeholder="Masukkan Custom Link">
                                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <ValidationProvider name="Periode" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Periode</label>
                                        <date-picker
                                            v-model="daterange_period"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih Tanggal Mulai & Selesai"
                                            range
                                            input-class="form-control"
                                            class="w-100"
                                            @input="
                                                model.start_date = daterange_period[0]
                                                model.end_date   = daterange_period[1]">
                                        </date-picker>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-lg-4">
                                <ValidationProvider name="Waktu Tayang" v-slot="{ errors }" rules="required">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Waktu Tayang</label>
                                        <div class="row">
                                            <div class="col">
                                                <input type="number" class="form-control" placeholder="Contoh: 5" v-model="model.showtime">
                                            </div>
                                            <div class="col align-self-center">
                                                Detik
                                            </div>
                                        </div>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-lg-4">
                                <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Status</label>
                                        <select name="" id="" class="form-control" v-model="model.is_active">
                                            <option :value="null">Pilih Status</option>
                                            <option :value="true">Aktif</option>
                                            <option :value="false">Tidak Aktif</option>
                                        </select>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required" >
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="" class="required">Deskripsi</label>
                                <quill-editor v-model="model.description" :options="editorOption"></quill-editor>
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="text-right">
                            <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                            <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <!-- <json-viewer :value="model"></json-viewer> -->
    </div>
</template>
<script>
const toolbarOptions = [
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['clean'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['blockquote', 'code-block']
]
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            dropzoneOptions: {
                url: process.env.VUE_APP_BASE_URL + '/misc/upload',
                thumbnailWidth: 500,
                maxFilesize: 0.5,
                maxFiles: 1,
                addRemoveLinks: true
            },
            model: {
                image: null,
                name: null,
                link_type: null,
                link_json: {},
                start_date: null,
                end_date: null,
                showtime: null,
                is_active: null,
                description: null,
            },
            daterange_period: null,
            isLoading: false,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    },
                },
                placeholder: "Masukkan Deskripsi"
            },
        }
    },
    methods: {
        show() {
            let _ = this
            _.axios.get('/sponsor/banner/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.daterange_period = [ _.$moment(_.model.start_date).format('YYYY-MM-DD'), _.$moment(_.model.end_date).format('YYYY-MM-DD') ]
                    var file = { size: 123, name: "Icon", type: "image/png" };
                    var url = _.model.image;
                    this.$refs.myVueDropzone.manuallyAddFile(file, url);
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.$route.params.id) {
                    _.update()
                    return
                }

                let data = {
                    ..._.model,
                    link_json: JSON.stringify(_.model.link_json)
                }

                _.axios.post('/sponsor/banner', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.$router.push('/sponsors')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this
            let data = {
                ..._.model,
                link_json: JSON.stringify(_.model.link_json)
            }
            _.axios.put('/sponsor/banner/' + _.$route.params.id, data)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.$router.push('/sponsors')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        },
        file_successfully_uploaded(file, response) {
            let _ = this
            _.model.image = response.data.url
        }
    },
    mounted() {
        let _ = this

        if (_.$route.params.id){
            _.show()
        }
    }
}
</script>