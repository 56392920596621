<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Role</h3>                        
                <ValidationObserver class="card-content" ref="observer">
                    <form @submit.prevent="store()">
                        <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Nama Role</label>
                                <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan Nama Role">
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="row">
                            <div class="col-lg-6 mb-3" v-for="permission in permissions" :key="permission.id">
                                <div class="row">
                                    <div class="col"><p class="mb-0 f-16">{{ permission.name }}</p></div>
                                    <div class="col-3 row">
                                        <input type="radio" class="align-self-center mr-1" value="1" v-model="model_permissions[permission.id-1]">
                                        <label for="" class="align-self-center mb-0">Ya</label>
                                    </div>
                                    <div class="col-3 row">
                                        <input type="radio" class="align-self-center mr-1" value="0" v-model="model_permissions[permission.id-1]">
                                        <label for="" class="align-self-center mb-0">Tidak</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right">
                            <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                            <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                name: null,
                permissions: []
            },
            permissions: [],
            model_permissions: [],
            isLoading: false,
            req_permissions: []
        }
    },
    methods: {
        get_permissions() {
            let _ = this
            _.axios.get('/role/permissions', _.model)
                .then(resp=>{
                    _.permissions = resp.data.data

                    if (!_.$route.params.id) {
                        let temp = []
                        for (let idx = 0; idx < _.permissions.length; idx++) {
                            temp.push('0')
                        }
                        _.model_permissions = temp
                    }
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        show() {
            let _ = this
            _.axios.get('/role/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data

                    for (let idx = 0; idx < _.model.permissions.length; idx++) {
                        _.model_permissions.push(_.model.permissions[idx].name);
                    }

                    let temp = []
                    for (let idx = 0; idx < _.permissions.length; idx++) {
                        if(_.model_permissions.indexOf(_.permissions[idx].name) != -1){
                            temp.push('1')
                        } else {
                            temp.push('0')
                        }
                    }
                    _.model_permissions = temp
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.$route.params.id) {
                    _.update()
                    return
                }

                let data = {
                    name: _.model.name,
                    permissions: _.req_permissions
                }
                _.axios.post('/role', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.$router.push('/roles')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this
            let data = {
                name: _.model.name,
                permissions: _.req_permissions
            }
            _.axios.put('/role/' + _.$route.params.id, data)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.$router.push('/roles')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        }
    },
    mounted() {
        let _ = this

        _.get_permissions()
        if (_.$route.params.id){
            _.show()
        }
    },
    watch: {
        model_permissions: function() {
            this.req_permissions = []
            for (let i = 0; i < this.permissions.length; i++) {
                if (this.model_permissions[i] == "1") {
                    this.req_permissions.push(this.permissions[i].name)
                }
            }
        }
    }
}
</script>